export enum RoutePath {
  BASE = "/",
  HOMEPAGE = "/games",
  ADMIN_HOMEPAGE = "/admin",
  LOGIN = "/login/:ref?",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password/:token/:newPassword?",
  USERS = "/users",
  REPORTS = "/reports",
  TENANTS = "/tenants",
  ERROR_404 = "/404",
  ERROR_403 = "/403",
  SERVER_ERROR = "/server-error/:code",
  FALLBACK = "/*",
  TESTED_PERSONS = '/tested-persons',
  EXAM_GROUPS = '/exam-groups',
  EXAM = "/exam/:id",
  EXAM_RESULT = "/examined-person-exam-result/:token",
  RESULTS_FOR_EMPLOYER = '/results-for-employer',
  BATCH_RESULTS = "/examined-person-exam-result-batch/:tenantId/:from/:to/:groups",
  FINISED_EXAM_WITH_PDF = '/finished-exam-with-pdf',
  FINISED_EXAM_WITHOUT_PDF = '/finished-exam-without-pdf'
}
