import React, { FC, useEffect } from 'react'
import { useQuery } from '@apollo/client';
import { ExamGroupItemNode } from '../../../examGroup/examGroup.types';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { EXAM_GROUP_USER_QUERY } from './examGroup.query';
import TextFieldController, { TextFieldControllerProps } from '../../../formItems/textFieldController/TextFieldController.component';

interface ExamGroupControllerProps
  extends TextFieldControllerProps
{
  tenantId?: string | string[];
  multiple?: boolean;
}

const ExamGroupController: FC<ExamGroupControllerProps> = ({
  tenantId,
  disabled,
  multiple= true,
  ...props
}) => {
  const { data, loading, refetch } = useQuery<{ connection: {examGroups:{ edges: Array<{ node: ExamGroupItemNode}> }}}>(EXAM_GROUP_USER_QUERY, {
    variables: {
      tenantId
    },
    skip: !tenantId || tenantId.length === 0
  });

  useEffect(() => {
    if(!tenantId || tenantId?.length !== 0) {
      refetch()
    }
  }, [tenantId])

  return (
    <TextFieldController
      select
      {...props}
      disabled={loading || disabled || (!tenantId || tenantId?.length === 0)}
      SelectProps={{
        multiple
      }}
    >
      {data?.connection.examGroups.edges.length ? (
        data.connection.examGroups.edges.map((option) => (
          <MenuItem
            key={option.node.examGroupId}
            value={option.node.examGroupId}
          >
            {option.node.name}
          </MenuItem>
        ))
        ) : (
          <Box m={2}>
            <Typography variant={"body2"}>
              <i>Brak podmiotów</i>
            </Typography>
          </Box>
        )
      }
    </TextFieldController>
  )
}

export default ExamGroupController