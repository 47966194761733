import { gql } from "@apollo/client"

export const USERS_LIST_QUERY = gql`
    query GetUsersList(
        $first: Int,
        $offset: Int,
        $filter: UserFilter,
        $orderBy: [UsersOrderBy!]
    ) {
        connection: users(first: $first, offset: $offset, filter: $filter, orderBy: $orderBy) {
            totalCount
            edges {
                node {
                    userId
                    email
                    name
                    surname
                    fullName
                    userRole
                    archived
                    occupationType {
                      occupationTypeId
                      name
                    }
                    tenantsList {
                        tenantId
                        name
                    }
                    workplaceThreats
                    guardian {
                        name
                        surname
                    }
                    birthYear
                    age
                    ageCategoryId
                    genderId
                    motivation
                    seniorityId
                    educationId
                    weigh
                    height
                    bmi
                    workTypeId
                }
            }
        }
    }
`;
