import React, { FC } from 'react'
import TextFieldController, { TextFieldControllerProps } from '../../formItems/textFieldController/TextFieldController.component';
import { useQuery } from '@apollo/client';
import { EXAM_GROUP_LIST_QUERY } from './examGroups.query';
import { ExamGroupItemNode } from '../examGroup.types';
import { Box, MenuItem, Typography } from '@material-ui/core';

interface ExamGroupMultiSelectControllerProps
  extends TextFieldControllerProps
{
  multiple?: boolean;
}

const ExamGroupMultiSelectController: FC<ExamGroupMultiSelectControllerProps> = ({
  disabled,
  multiple= true,
  ...props
}) => {
  const { data, loading } = useQuery<{ connection: { edges: Array<{ node: ExamGroupItemNode}>}}>(EXAM_GROUP_LIST_QUERY);

  return (
    <TextFieldController
      select
      {...props}
      disabled={loading || disabled}
      SelectProps={{
        multiple
      }}
    >
      {data?.connection.edges.length ? (
        data.connection.edges.map((option) => (
          <MenuItem
            key={option.node.examGroupId}
            value={option.node.examGroupId}
          >
            {option.node.name}
          </MenuItem>
        ))
        ) : (
          <Box m={2}>
            <Typography variant={"body2"}>
              <i>Brak podmiotów</i>
            </Typography>
          </Box>
        )
      }
    </TextFieldController>
  )
}

export default ExamGroupMultiSelectController