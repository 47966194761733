import { useMutation } from '@apollo/client';
import React, {FC} from 'react'
import { CREATE_EXAM } from '../examQuestionCard/ExamQuestionCard.query';
import { Box, Button } from '@material-ui/core';

interface StartExamProps {
  id: string;
  startExam: () => void;
}

const StartExam: FC<StartExamProps> = ({id, startExam}) => {
  const [mutation, {loading}] = useMutation(CREATE_EXAM)

  const handleStartExam = async () => {
    try {
      await mutation({
        variables: {
          input: {
            examId: id
          }
        }
      })
      startExam()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      padding: 24
    }}>
      <Button 
        variant='contained'
        color='primary'
        size='large'
        onClick={handleStartExam}
        disabled={loading}
      >
        Rozpocznij Badanie
      </Button>
    </Box>
  )
}

export default StartExam