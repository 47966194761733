import { FC } from 'react'
import Popup from '../../common/popup/Popup.component';
import { Typography } from '@material-ui/core';
import useFileDownload from '../../../hooks/useFileDownload';
import { useHistory } from 'react-router';

interface GeneratePdfResultModalProps {
  open: boolean;
  token?: string
  closeModal: () => void
}

const GeneratePdfResultModal: FC<GeneratePdfResultModalProps> = ({
  open,
  token,
  closeModal
}) => {
  const {push} = useHistory()
  const {download, downloading} = useFileDownload(`/api/single-result-print/${token}`);

  const handleClose = () => {
    closeModal()
    push("/finished-exam-without-pdf")
  }

  return (
    <Popup
      open={open}
      title="Badanie zakończone"
      actionButtonText='Wygeneruj'
      actionButtonProps={{
        onClick: () => {
          download("Wyniki badania.pdf")
          push("/finished-exam-with-pdf")
        },
        disabled: downloading
      }}
      onClose={handleClose}
      closeButtonProps={{
        disabled: downloading
      }}
    >
      <Typography>
        Czy chcesz wygenerować wyniki?
      </Typography>
    </Popup>
  )
}

export default GeneratePdfResultModal