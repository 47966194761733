import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useExamGroupsListStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldName: {
      ...theme.typography.body2,
      fontWeight: "bold",
    },
    chip: {
      marginRight: theme.spacing(1),
    },
  }),
)
