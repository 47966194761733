import { FC } from 'react'
import DatePickerController from '../datePickerController/DatePickerController';
import { Box } from '@material-ui/core';

interface IDateRangeController {
  nameFrom: string;
  nameTo: string;
  labelNameFrom?: string;
  labelNameTo?: string;
  disabled?: boolean;
}

const DateRangeController: FC<IDateRangeController> = (
  {
    nameFrom,
    nameTo,
    labelNameFrom = "Data od",
    labelNameTo = "Data do",
    disabled,
  }
) => {
  return (
    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "start", maxWidth: "100%"}}>
      <DatePickerController 
        name={nameFrom}
        label={labelNameFrom}
        disabled={disabled}
      />

      <div style={{margin: "0 6px"}}/>

      <DatePickerController 
        name={nameTo}
        label={labelNameTo}
        disabled={disabled}
      />
    </Box>
  )
}

export default DateRangeController