import { Box, Tooltip, Typography } from '@material-ui/core';
import React, { FC } from 'react'
import { useCustomProgressStyles } from './customProgress.styles';

interface CustomProgressProps {
  title: string;
  value: number
}

const CustomProgress: FC<CustomProgressProps> = ({ title, value }) => {
  const classes = useCustomProgressStyles()

  return (
    <Box>
      <Typography variant='h5'>{title}</Typography>
        <Tooltip title={value}>
          <div className={classes.bar}>
            <div className={classes.fill} style={{width: `${value * 10}%`}}/>
          </div>
        </Tooltip>
    </Box>
  )
}

export default CustomProgress