import React, { FC } from "react"
import {Box, Typography} from "@material-ui/core"
import {useProjectDataLogosStyles} from "./ProjectDataLogos.styles";

import ciopLogo from "../../../assets/images/ciop.png"
import kiLogo from "../../../assets/images/ki_new.png"
import ukswLogo from "../../../assets/images/uksw_new.png"

interface ProjectDataLogosProps {}

const ProjectDataLogos: FC<ProjectDataLogosProps> = () => {
  const classes = useProjectDataLogosStyles()

  return (
    <Box className={"only-print"}>

      <Box className={classes.box}>
        <img src={ukswLogo} alt="" className={classes.img}/>
        <img src={kiLogo} alt="" className={classes.img}/>
        <img src={ciopLogo} alt="" className={classes.img}/>
      </Box>
      
      <Box mb={3} px={6}>
        <Typography variant={"h5"}>
          BIURO REALIZACJI PROJEKTU
        </Typography>
        <Typography variant={"subtitle2"}>
          Uniwersytet Kardynała Stefana Wyszyńskiego, Wydział Nauk Pedagogicznych<br/>
          ul. Wóycickiego 1/3, 01-938 Warszawa, Budynek 21, pok. 316a<br/>
          Kierownik projektu: dr Marek Kulesza
        </Typography>
      </Box>

      <Box mb={3} px={6}>
        <Typography variant={"subtitle2"}>
          Projekt Sygnalizator Plus – analiza sytuacji pracowników w wieku emerytalnym i przedemerytalnym w przedsiębiorstwach w oparciu o klasyfikację ICF (nr projektu POWR.04.03.00-00-0026/20)
        </Typography>
      </Box>
    </Box>
  )
}

export default ProjectDataLogos
