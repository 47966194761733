import React, {FC} from "react"
import { Box, Typography } from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import { useMutation } from "@apollo/client";
import { ARCHIVE_EXAM_GROUP } from "./ArchiveExamGroup.mutation"
import { ExamGroupItemNode } from "../examGroup.types";

interface ArchiveExamGroupPopupProps {
  examGroup: ExamGroupItemNode;
  open: boolean;
  onClose(): void;
  refetch(): void;
}

const ArchiveExamGroupPopup: FC<ArchiveExamGroupPopupProps> = ({examGroup, open, refetch, onClose}) => {
  const [archiveExamGroup, {loading}] = useMutation(ARCHIVE_EXAM_GROUP)

  const { examGroupId, name } = examGroup

  const handleSubmit = async () => {
    try {
      await archiveExamGroup({
        variables: {
          input: {
            examGroupId: examGroupId,
          }
        }
      })

      await refetch()
      onClose()
    } catch (exception) {
      // processResponseException(form, exception);
    }
  }

  return (
    <Popup
      open={open}
      onClose={onClose}
      title="Archiwizuj grupę badań"
      closeButtonText={"Nie, zamknij"}
      actionButtonText={"Tak, potwierdź"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
    >
      <Box>
        <Typography>
          Czy na pewno chcesz zarchiwizować grupę do badań <b>{ name }</b>?
        </Typography>
        <Typography>
          <b>Uwaga!</b> Operacja archiwizacji jest nieodwracalna.
        </Typography>
      </Box>
    </Popup>
  )
}

export default ArchiveExamGroupPopup
