import { Box, Container, Typography, useTheme } from '@material-ui/core';
import React from 'react'

const FinishedExamWithPdfPage = () => {
  const theme = useTheme()
  return (
    <Container maxWidth="md">
      <Box 
        my={12} 
        bgcolor={theme.palette.background.paper} 
        p={4} 
        borderRadius={theme.shape.borderRadius}
      >
        <Typography variant='h4'>
          Dziękujemy za wypełnienie badania
        </Typography>

        <Box mt={4}>
          <Typography>
            Pobieranie podsumowania wkrótce się rozpocznie
          </Typography>
        </Box>
      </Box>
    </Container>
  )
}

export default FinishedExamWithPdfPage