import React from 'react'

const AdditionalSourcesOfInformation = () => {
  const books = [
    {
      title: "Zarządzanie zdrowiem w firmie a starzenie się personelu – informator, IMP 2022",
      href: "https://pracanazdrowie.pl/wp-content/uploads/zarzadzanie-zdrowiem-w-firmie-a-starzenie-sie-personelu-informator.pdf"
    },
    {
      title: "Jak firma może pomóc pracownikom w zdrowym odżywianiu i utrzymaniu prawidłowej masy ciała? IMP 2018",
      href: "https://promocjazdrowiawpracy.pl/wp-content/uploads/2018/10/Jak-firma-moze-pomooc-pracownikom-w-zdrowym-odzywianiu-i-utrzymaniu-prawidlowej-masy-cia%C5%82a1.pdf"
    },
    {
      title: "Jak zakład pracy może zadbać o kondycję fizyczną personelu? IMP 2018",
      href: "https://promocjazdrowiawpracy.pl/wp-content/uploads/2018/10/Jak-zaklad-pracy-moze-zadbac-o-kondycjee-fizyczna-personelu.pdf"
    },
    {
      title: "Jak zadbać o kondycję w pracy umysłowej, CIOP-PIB 2019",
      href: "https://m.ciop.pl/CIOPPortalWAR/file/89523/Poradnik-I-N-02-J-Kaminska.pdf"
    },
    {
      title: "Profilaktyka przewlekłej niewydolności żylnej kończyn dolnych wśród osób wykonujących pracę w pozycji siedzącej i stojącej, CIOP-PIB 2016",
      href: "https://m.ciop.pl/CIOPPortalWAR/file/79745/Profilaktyka-przewleklej-niewydolnosci-zylnej-mat-informacyjne-2016.pdf"
    },
    {
      title: "Dlaczego warto zatrudniać osoby z dysfunkcją wzroku, Polski Związek Niewidomych 2015",
      href: "http://nowa.pzn.org.pl/wp-content/uploads/2015/09/PRACODAWCY_BROSZURA.pdf"
    },
    {
      title: "Pracownik starszy a obciążenie cieplne, CIOP-PIB 2019",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/89415/Ulotka-2-G-08-2019-M-Mlynarczyk.pdf"
    },
    {
      title: "Poradnik doboru i zastosowania urządzeń wspomagających przenoszenie ładunków na wybranych stanowiskach pracy i podczas wykonywania typowych czynności podnoszenia i przenoszenia, CIOP-PIB 2016",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/79681/Poradnik-doboru-i-zastosowania-urzadzen-wspomagajacych-przenoszenie.pdf"
    },
    {
      title: "Wytyczne dla pracodawców dotyczące kształtowania właściwych psychospołecznych warunków pracy dla osób z depresją, CIOP-PIB 2019",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/96364/Wytyczne_dla_pracodawcow-depresja.pdf"
    },
    {
      title: "Program treningu uważności z elementami terapii poznawczej dla osób z depresją, CIOP-PIB 2019",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/96362/Program_treningu_uwaznosci_z_elementami_terapii_poznawczej_dla_osob_z_depresja.pdf"
    },
    {
      title: "Profilaktyka zaburzeń poznawczych i obszarów wsparcia starszych pracowników, CIOP-PIB 2022",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/95893/Profilaktyka_zaburzen_poznawczych.pdf"
    },
    {
      title: "Hałas na stanowisku pracy biurowej, CIOP-PIB 2022",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/96343/Halas-na-stanowisku-pracy-biurowej-IPB01-J-Kaminska.pdf"
    },
    {
      title: "Warunki i organizacja pracy dla osób z cukrzycą, CIOP-PIB 2016",
      href: "https://m.ciop.pl/CIOPPortalWAR/file/91767/Warunki-i-organizacja-pracy-cukrzyca-CIOP-PIB-2016.pdf"
    },
    {
      title: "Stereotypy związane z wiekiem a funkcjonowanie zawodowe pracowników w wieku 50+, CIOP-PIB 2016",
      href: "https://m.ciop.pl/CIOPPortalWAR/file/83912/Stereotypy-50-plus-Z-Mockallo-I-P-17.pdf"
    },
    {
      title: "Jak zwiększyć sprawność poznawczą i zmniejszyć obciążenie pracą zmianową, CIOP-PIB 2019",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/88342/2019100332826&Poradnik-I-N-03-S-Suminska..pdf"
    },
    {
      title: "Dostosowanie stanowisk pracy do możliwości fizycznych starszych pracowników",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/79693/PORADNIK-17-03-2017.pdf"
    }
  ]
  return (
    <div>
      <ul>
        {books.map(book => (
          <li>
            <a href={book.href} target="_blank" rel="noreferrer noopener">
              {book.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AdditionalSourcesOfInformation