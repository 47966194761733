import React from 'react'

const ExamAdditionalSourcesOfInformation = () => {
  const books = [
    {
      title: "Zachowania prozdrowotne związane ze stylem życia w kontekście ekspozycji zawodowej. Jak pozostać aktywnym zawodowo mimo upływu czasu i pogarszania się zdrowia? Dlaczego warto pracować jak najdłużej? IMP 2022",
      href: "https://pracanazdrowie.pl/wp-content/uploads/npz-zachowania-prozdrowotne-broszura-dla-pracownikow.pdf"
    },
    {
      title: "Wiem, że dobrze jem - Talerz zdrowego żywienia w praktyce, NCEiŻ 2021",
      href: "https://ncez.pzh.gov.pl/wp-content/uploads/2021/11/e-book-Talerz-Zdrowego-Zywienia.pdf"
    },
    {
      title: "Jak zadbać o kondycję fizyczną w pracy umysłowej, CIOP-PIB 2019",
      href: "https://m.ciop.pl/CIOPPortalWAR/file/89522/Materialy-informacyjne-I-N-02-J-Kaminska.pdf"
    },
    {
      title: "Jak zadbać o kondycję w pracy umysłowej, CIOP-PIB 2019",
      href: "https://m.ciop.pl/CIOPPortalWAR/file/89523/Poradnik-I-N-02-J-Kaminska.pdf"
    },
    {
      title: "Profilaktyka przewlekłej niewydolności żylnej kończyn dolnych wśród osób wykonujących pracę w pozycji siedzącej i stojącej, CIOP-PIB 2016",
      href: "https://m.ciop.pl/CIOPPortalWAR/file/79745/Profilaktyka-przewleklej-niewydolnosci-zylnej-mat-informacyjne-2016.pdf"
    },
    {
      title: "Jak sobie radzić z obciążeniem pracą zmianową, jak poprawić sprawność poznawczą i odpoczywać efektywnie, CIOP-PIB 2019",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/88341/2019100332151&Materialy-informacyjne-pracownik-I-N-03-S-Suminska.pdf"
    },
    {
      title: "Program ćwiczeń mających na celu poprawę zdolności koordynacyjnych i samokontroli położenia ciała w przestrzeni, CIOP-PIB 2019",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/88663/2019103103051&Program-cwiczen-I-N-11-J-Mazur-Rozycka.pdf"
    },
    {
      title: "Poradnik doboru i zastosowania urządzeń wspomagających przenoszenie ładunków na wybranych stanowiskach pracy i podczas wykonywania typowych czynności podnoszenia i przenoszenia, CIOP-PIB 2016",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/79681/Poradnik-doboru-i-zastosowania-urzadzen-wspomagajacych-przenoszenie.pdf"
    },
    {
      title: "Program treningu uważności z elementami terapii poznawczej dla osób z depresją, CIOP-PIB 2019",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/96362/Program_treningu_uwaznosci_z_elementami_terapii_poznawczej_dla_osob_z_depresja.pdf"
    },
    {
      title: "Radzenie sobie ze stresem, CIOP-PIB 2022",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/96311/Radzenie_sobie_ze_stresem.pdf"
    },
    {
      title: "Hałas na stanowisku pracy biurowej, CIOP-PIB 2022",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/96343/Halas-na-stanowisku-pracy-biurowej-IPB01-J-Kaminska.pdf"
    },
    {
      title: "Warunki i organizacja pracy dla osób z cukrzycą, CIOP-PIB 2016",
      href: "https://m.ciop.pl/CIOPPortalWAR/file/91767/Warunki-i-organizacja-pracy-cukrzyca-CIOP-PIB-2016.pdf"
    },
    {
      title: "Jak zwiększyć sprawność poznawczą i zmniejszyć obciążenie pracą zmianową, CIOP-PIB 2019",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/88342/2019100332826&Poradnik-I-N-03-S-Suminska..pdf"
    },
    {
      title: "Warunki i organizacja pracy dla osób z chorobami układu sercowo-naczyniowego, CIOP-PIB 2016",
      href: "https://m.ciop.pl/CIOPPortalWAR/file/91766/2021021741812&Warunki-i-organizacja-pracy-choroby-ukladu-sercowo-naczyniowego-CIOP-PIB-2016.pdf"
    },
    {
      title: "Zalecenia dotyczące stylu życia, warunków i organizacji pracy dla osób z chorobą zwyrodnieniową stawów, CIOP-PIB 2017",
      href: "https://www.ciop.pl/CIOPPortalWAR/file/91765/2021021741645&Warunki-i-organizacja-pracy-choroba-zwyrodnieniowa-stawow-CIOP-PIB-2017.pdf"
    },
    {
      title: "Kontrakt psychologiczny pomiędzy pracownikiem a pracodawcą, CIOP-PIB 2017",
      href: "Kontrakt-psychologiczny.pdf (ciop.pl)"
    }
  ]
  return (
    <div>
      <ul>
        {books.map(book => (
          <li>
            <a href={book.href} target="_blank" rel="noreferrer noopener">
              {book.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ExamAdditionalSourcesOfInformation