export const requiredErrorMessage = "To pole jest wymagane"
export const emailErrorMessage = "Niepoprawny adres e-mail"
export const numberErrorMessage = "Niepoprawny format liczby"
export const maxNumberErrorMessage = (maxNr: number) => `Maksymalna wartość dla tego pola wynosi ${maxNr}`
export const wrongValueErrorMessage = "Ta wartość jest nieprawidłowa"
export const wrongRepeatPassword = "Podane hasła nie są identyczne"
export const exactOneValue = "Wymagana dokładnie jedna wartość"
export const maxStringLength = (maxNr: number) => `Maksymalna liczba znaków wynosi ${maxNr}`

// password
export const min8CharsErrorMessage = "Hasło musi mieć minimum 8 znaków"
export const min1CapitalLetterErrorMessage = "Hasło musi mieć minimum 1 wielką literę"
export const min1SmallLetterErrorMessage = "Hasło musi mieć minimum 1 małą literę"
export const minOneSpecialCharacterErrorMessage = "Hasło musi mieć minimum 1 znak specjalny"
// e/o password