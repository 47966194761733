import React, {FC, useState} from 'react'
import {Tooltip, IconButton} from "@material-ui/core";
import {
  Link, 
} from '@material-ui/icons';
import GlobalSuccessSnackbar from "../../formItems/globalSuccessSnackbar/GlobalSuccessSnackbar";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import generateUuid from '../../../utils/generateUuid';
import { useMutation } from '@apollo/client';
import { CREATE_EXAM } from './createExam.mutation';
interface ExamsManagementIconsProps {
  userId: string
}

const ExamsManagementIcons: FC<ExamsManagementIconsProps> = ({
  userId,
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false)
  const id = generateUuid()
  const [mutation, {loading}] = useMutation(CREATE_EXAM)

  const createExam = async (examId: string) => {
    try {
      await mutation({
        variables: {
          input: {
            exam: {
              examId,
              examinedUserId: userId,
            }
          }
        }
      })
      setIsCopied(true)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div>
      <Tooltip title={"Generowanie linku do badania"}>
        <CopyToClipboard text={`${window.location.origin}/exam/${id}`}>
          <IconButton onClick={() => createExam(id)} disabled={loading}>
            <Link />
          </IconButton>
        </CopyToClipboard>
      </Tooltip>

      <GlobalSuccessSnackbar
        isSuccess={isCopied}
        successMessage={"Skopiowano link do schowka"}
      />
    </div>
  )
}

export default ExamsManagementIcons