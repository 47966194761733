import React, {FC} from "react"
import {Box, TableBody, TableHead, Typography} from "@material-ui/core"
import {EXAM_GROUPS_LIST_QUERY} from "./ExamGroupsList.query"
import useRelayConnection from "../../../hooks/useRelayConnection"
import {usePaginationParams} from "../../../hooks/useQueryParams"
import TableWithPagination from "../../common/tableWithPagination/TableWithPagination.component"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import ExamGroupsListActions from "../examGroupsListActions/ExamGroupsListActions.component"
import {useExamGroupsListStyles} from "./ExamGroupsList.styles"
import AddExamGroupPopup from "../addExamGroupPopup/AddExamGroup.component"
import TableCellWithFilter from "../../common/tableCellWithFilter/TableCellWithFilter.component"
import {StringParam, useQueryParam} from "use-query-params"
import {FilterSelectType} from "../../common/tableCellWithFilter/TableCellWithFilter.types"
import {ExamGroupItemNode} from "../examGroup.types";

interface ExamGroupsListProps {
  isOpenAddExamGroupPopup: boolean;
  onCloseAddExamGroupPopup(): void;
}

const ExamGroupsList: FC<ExamGroupsListProps> = ({isOpenAddExamGroupPopup, onCloseAddExamGroupPopup}) => {
  const classes = useExamGroupsListStyles()

  const [tenantIdFilter, setTenantIdFilter] = useQueryParam("tenantId", StringParam)

  const filters = {
    tenantId: tenantIdFilter ? {equalTo: tenantIdFilter}: undefined
  }

  const {tableProps, refetch, loading} = useRelayConnection<ExamGroupItemNode>(EXAM_GROUPS_LIST_QUERY, {
    variables: {
      filter: tenantIdFilter ? filters : undefined,
      orderBy: 'EXAM_GROUP_ID_ASC'
    },
    pagination: usePaginationParams()
  })

  const getDate = (date: string) => {
    const dateObj = new Date(date.split("T")[0])
    const day = dateObj.getDate()
    const month = dateObj.getMonth() + 1
    const year = dateObj.getFullYear();
    return `${day}.${month}.${year}`
  }

  return (
    <Box>
      <AddExamGroupPopup
        open={isOpenAddExamGroupPopup}
        refetch={refetch}
        onClose={onCloseAddExamGroupPopup}
      />

      <TableWithPagination {...tableProps}>
        <TableHead>
          <TableRow>
            <TableCellWithFilter
              defaultValue={tenantIdFilter}
              label="Podmiot"
              disabled={loading}
              select={FilterSelectType.TENANTS}
              onFilterSubmit={(val) => setTenantIdFilter(val)}
            >
              <Typography className={classes.fieldName}>Podmiot</Typography>
            </TableCellWithFilter>

            <TableCell>
              <Typography className={classes.fieldName}>Nazwa grupy do badań</Typography>
            </TableCell>

            <TableCell>
              <Typography className={classes.fieldName}>Data dodania</Typography>
            </TableCell>

            <TableCell>
              <Typography className={classes.fieldName} align={"right"}>Akcje</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <>
            { tableProps.dataSource.map((dataItem: ExamGroupItemNode) => (
              <TableRow key={dataItem.examGroupId}>
                <TableCell>
                  {dataItem.tenant.name}
                </TableCell>

                <TableCell>
                  {dataItem.name}
                </TableCell>

                <TableCell>
                  {getDate(dataItem.createdAt)}
                </TableCell>

                <TableCell align={"right"}>
                  {!dataItem.userscount && (
                    <ExamGroupsListActions
                      examGroup={dataItem}
                      refetch={refetch}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </>
        </TableBody>
      </TableWithPagination>
    </Box>
  )
}

export default ExamGroupsList
