import React, {FC, useState} from 'react'
import {Button, ButtonGroup, Tooltip} from "@material-ui/core";
import ArchiveUserPopup from "../../user/archiveUserPopup/ArchiveUser.component";
import EditUserPopup from "../../user/editUserPopup/EditUser.component";
import {ExaminedPersonUserItemNode} from "../../../store/user/user.types";
import {useUsersListActionsStyles} from "../../user/usersListActions/UsersListActions.styles";
import PreviewUserPopup from "../../user/previewUserPopup/PreviewUser.component";
import {EditOutlined, ArchiveOutlined, VisibilityOutlined} from '@material-ui/icons'

interface UsersListActionsProps {
  user: ExaminedPersonUserItemNode;
  refetch(): void;
}

const TestedPersonsActions: FC<UsersListActionsProps> = ({ user, refetch })=> {
  const classes = useUsersListActionsStyles()

  const [isOpenEditUserPopup, setIsOpenEditUserPopup] = useState<boolean>(false)
  const [isOpenArchiveUserPopup, setIsOpenArchiveUserPopup] = useState<boolean>(false)
  const [isOpenPreviewUserPopup, setIsOpenPreviewUserPopup] = useState<boolean>(false)

  return(
      <ButtonGroup>
        <Tooltip title={'Edytuj użytkownika'}>
          <Button
            className={classes.button}
            onClick={() => setIsOpenEditUserPopup(true)}
          >
            <EditOutlined className={classes.icon}/>
          </Button>
        </Tooltip>

          <Tooltip title={'Podgląd użytkownika'}>
            <Button
              className={classes.button}
              onClick={() => setIsOpenPreviewUserPopup(true)}
            >
              <VisibilityOutlined className={classes.icon}/>
            </Button>
        </Tooltip>

          <Tooltip title={'Archiwizuj użytkownika'}>
            <Button
              className={classes.button}
              onClick={() => setIsOpenArchiveUserPopup(true)}
            >
              <ArchiveOutlined className={classes.icon}/>
            </Button>
          </Tooltip>

        <ArchiveUserPopup
          user={user}
          open={isOpenArchiveUserPopup}
          refetch={refetch}
          onClose={() => setIsOpenArchiveUserPopup(false)}
        />

        <EditUserPopup
          user={user}
          open={isOpenEditUserPopup}
          refetch={refetch}
          onClose={() => setIsOpenEditUserPopup(false)}
        />

        <PreviewUserPopup
          user={user}
          open={isOpenPreviewUserPopup}
          onClose={() => setIsOpenPreviewUserPopup(false)}
        />

      </ButtonGroup>
  )
}

export default TestedPersonsActions