export const recommendations = [
  {
    code: "b130",
    title: "Funkcje energii i napędu",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "U pracowników w starszym wieku może pojawić się obniżenie funkcji energii życiowej i napędu, które pobudzają do działania na rzecz zaspakajania potrzeb i osiągania celów. Zmiany te mogą mieć podłoże  fizjologiczne i psychologiczne – mogą być np. wynikiem towarzyszących chorób lub wynikać z trudnych doświadczeń rodzinnych pracownika. Osoby z obniżeniem aktywności ogólnej mogą w większym stopniu odczuwać obciążenie psychiczne związane z presją czasu w pracy, koniecznością podejmowania decyzji lub w pracy w kontakcie z klientem. W takich sytuacjach Pracodawca może rozważyć możliwość modyfikacji warunków pracy w kierunku zmniejszenia presji czasu, obciążenia wynikającego z konieczności podejmowania decyzji, kontaktów z klientami lub zapewnienia wsparcia ze strony kolegów i przełożonych. Jest bardzo ważne, aby przy akceptacji pracownika wspierać rodzaje aktywności społecznej (również pozazawodowej). Dzięki tym działaniom zwiększamy samoocenę i przekazujemy wsparcie społeczne. "
  },
  {
    code: "b134",
    title: "Funkcje snu",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "W grupie osób narażonych na zaburzenia jakości snu są np. osoby w podeszłym wieku, kobiety w okresie klimakterium lub pracownicy pracujący w systemie zmianowym. Przyczyny zaburzeń snu mogą być związane z warunkami otoczenia (np. hałas, upały), złymi nawykami (np. nieregularne pory snu, ciężkostrawne posiłki przed planowanym snem, korzystanie z wyświetlaczy lub monitorów w godzinach wieczornych, niewygodny materac). Przyczyny zaburzeń snu mogą wiązać się z chorobami (np. depresja, stany lękowe), przeżywaniem trudnych doświadczeń lub towarzyszącemu bólowi w różnych obszarach (np. bóle kręgosłupa, migreny, bóle pourazowe). Konsekwencjami niedoboru snu mogą być: obniżenie funkcji poznawczych i zaburzenia uwagi, trudność zapamiętywania i uczenia się nowych rzeczy; obniżenie nastroju, stany lękowe; słabsza wydolność fizyczna, nadmierna męczliwość; większa podatność na infekcje i zakażenia; zwiększenie ryzyka chorób przewlekłych takich jak cukrzyca, nadciśnienie tętnicze i inne choroby kardiologiczne. Jako pracodawcy możemy wesprzeć pracownika z zaburzeniami snu poprzez działania zmierzające do redukcji stresu, zwiększenie wsparcia społecznego."
  },
  {
    code: "b140",
    title: "Funkcje uwagi",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "W grupie pracowników w starszym wieku mogą pojawić się trudności ze sprawnością uwagi (koncentracją, podzielnością lub przerzutnością). Problemy te mogą mieć różne przyczyny i konsekwencje. Z punktu widzenia Pracodawcy warto zweryfikować czy przy zadaniach angażujących uwagę, poziom sprawności pozwala na bezpieczne wykonywanie zadań. Osoby z obniżeniem sprawności uwagi mogą w większym stopniu odczuwać zmęczenie i napięcie na stanowiskach operatorskich, wymagających monitorowania sytuacji pracy lub przy obsłudze maszyn i urządzeń z ruchomymi elementami."
  },
  {
    code: "b144",
    title: "Funkcje pamięci",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "W sytuacji pracy u pracowników w starszym wieku mogą pojawić się trudności z pamięcią.  Sprawność pamięci jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznym treningiem funkcji poznawczych m.in. poprzez utrzymanie aktywności zawodowej. Zaburzenia mogą pojawiać się w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako skutek uboczny oddziaływania środków farmakologicznych. Z punktu widzenia Pracodawcy warto wspierać starszych pracowników poprzez organizację zespołów międzypokoleniowych, w których doświadczenie zawodowe starszych pracowników wykorzystywane jest przez mniej doświadczonych. Trudności wynikające z zaburzeń pamięci są szczególnie odczuwalne w sytuacjach zadaniowych z odczuwaną  presją czasu, podejmowaniem decyzji oraz w pracy w kontakcie z klientem."
  },
  {
    code: "b152",
    title: "Funkcje emocjonalne",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "W grupie pracowników starszych mogą pojawić się problemy z odczuwaniem i regulacją emocji (np. złości i gniewu, lęku lub chwiejności emocjonalnej). Mogą one wynikać z doświadczania silnego stresu wynikającego z presji czasu lub podejmowania decyzji w trudnych sytuacjach. Można przypuszczać, że osoby z takimi problemami mogą mieć trudności w wykonywaniu zadań w sytuacji pracy z klientem, szczególnie gdy mogą występować sytuacje konfliktowe. Pracodawca może zweryfikować, czy wprowadzenie dodatkowych działań wspierających (np. redukcja zadań potencjalnie emocjonujących, szkolenia z zakresu obsługi trudnego klienta, itp.) może wpłynąć na poprawę stanu emocjonalnego pracownika. Pracodawca może zweryfikować, czy modyfikacja określonych warunków organizacyjnych (np. zbyt dużej  ilości pracy, nieregularności obciążeń, itp.) może wpłynąć poprawę funkcji emocjonalnych."
  },
  {
    code: "b240",
    title: "Wrażenia związane z funkcją słyszenia i funkcją przedsionka",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Ocena wskazuje na niewielkiego/średniego stopnia upośledzenie funkcji słyszenia lub przedsionka u niektórych pracowników, co może wpływać na ich sprawne funkcjonowanie w życiu zawodowym i prywatnym. Przy zaburzeniach funkcji błędnika może dochodzić do trudności w utrzymaniu równowagi, którym towarzyszą zawroty głowy i nudności. Można rozważyć przyspieszenie badania profilaktycznego u lekarza medycyny pracy."
  },
  {
    code: "b280",
    title: "Czucie bólu",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Odczuwane przez pracownika dolegliwości bólowe mogą zwiększać dyskomfort związany z pracą zawodową. Elastyczne planowanie przerw w pracy, różnorodność czynności, dostosowanie zadań do jego indywidualnych możliwości psychofizycznych  oraz wsparcie współpracowników może dawać pracownikowi lepsze możliwości wykonywania pracy. Uzyskany wynik w strefie żółtej lub czerwonej wskazuje, że część pracowników istotnie odczuwa dolegliwości bólowe, w związku z tym może to wpływać na ich funkcjonowanie bez względu na rodzaj wykonywanej pracy."
  },
  {
    code: "b420",
    title: "Funkcje energii i napędu",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "W przypadku występowania u pracownika problemów z ciśnieniem tętniczym należy zwrócić uwagę na organizację pracy w mikroklimacie zimnym, gorącym lub zmiennym. Skurcz naczyń krwionośnych pod wpływem zimna powoduje zwiększenie oporu naczyniowego na obwodzie, czego następstwem jest podwyższenie ciśnienia skurczowego krwi i obciążenie serca pracą zwiększającą zapotrzebowanie na tlen. Z drugiej strony, praca w warunkach narażenia na niskie temperatury otoczenia może spowodować u osób z już istniejącymi chorobami układu krążenia (choroba wieńcowa serca, nadciśnienie tętnicze) pogorszenie parametrów hemodynamicznych i zaostrzenie dolegliwości. Wysiłek fizyczny można potraktować zarówno jako czynnik prewencyjny, jak i jako czynnik ryzyka w odniesieniu do chorób układu krążenia, w tym nadciśnienia. Konieczność podejmowania dużego wysiłku (np. podnoszenie i przenoszenie ładunków) w ramach obowiązków zawodowych może stać się przeciwwskazaniem do wykonywania zawodu w poszczególnych grupach osób ze schorzeniami kardiologicznymi. Wykonywanie przez wiele lat ciężkiej pracy fizycznej zwiększa ryzyko rozwoju chorób układu krążenia. W związku z tym należy (w przypadku stanowisk pracy osób starszych) zapewnić organizację pracy umożliwiającą wykonywanie różnorodnych czynności, a sumaryczny wydatek energetyczny powinien być na poziomie niskim lub średnim. Można rozważyć przyspieszenie badania profilaktycznego u lekarza medycyny pracy."
  },
  {
    code: "b455",
    title: "Funkcje związane z tolerancją wysiłku",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Wraz z wiekiem obniża się tolerancja wysiłku człowieka. Już od ok. 25 r.ż. obserwuje się regularny spadek wydolności fizycznej, a w 50 r.ż. wydolność fizyczna zmniejsza się do ok. 70% swej maksymalnej wartości. Wśród pracowników starszych wykonana praca może być odczuwalna jako bardziej wyczerpująca, zależnie od indywidualnych predyspozycji. W związku z tym obciążenie fizyczne związane z wykonywaniem czynności pracy może przewyższać tolerancję wysiłku pracownika. Należy wziąć pod uwagę weryfikację organizacji pracy, w szczególności ograniczenie czynności wymagających dużego wydatku energetycznego. Można rozważyć przyspieszenie badania profilaktycznego u lekarza medycyny pracy."
  },
  {
    code: "b730",
    title: "Funkcje związane z siłą mięśni",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Wraz z wiekiem dochodzi do stopniowej degeneracji układu nerwowego sterującego pracą mięśni, która jest głównym czynnikiem prowadzącym do obniżenia się masy mięśniowej.  Zmniejszenie masy mięśniowej sprzyja pogorszeniu funkcji mięśni co wyraża się spadkiem możliwości siłowych. W związku z powyższym na stanowiskach pracy, na których pracują osoby starsze należy ograniczyć wywierane siły o ok. 14% dla populacji mężczyzn i ok. 24% dla populacji kobiet. Uzyskany wynik w strefie żółtej lub czerwonej wskazuje, że część pracowników ma obniżoną siłę mięśni, co może być istotne w przypadku ręcznych prac transportowych lub czynności wymagających utrzymywania wymuszonych pozycji ciała. Można rozważyć przyspieszenie badania profilaktycznego u lekarza medycyny pracy."
  },
  {
    code: "d110",
    title: "Patrzenie",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Wraz z wiekiem mogą pogarszać się funkcje narządu wzroku (np. ostrości widzenia, adaptacji do ciemności, wrażliwości na olśnienie). Uzyskany wynik w strefie żółtej lub czerwonej wskazuje, że część pracowników ma pogorszony wzrok, co może być istotne szczególnie podczas wykonywania prac na wysokości. Wykonywanie pracy na wysokości przez osoby z dysfunkcja narządu wzroku niesie za sobą ryzyko wypadków przy pracy dlatego noszenie okularów korekcyjnych (nie dotyczy soczewek kontaktowych) stanowi przeciwwskazanie do zatrudnienia przy pracy na wysokości powyżej 3m.  Można rozważyć przyspieszenie badania profilaktycznego u lekarza medycyny pracy."
  },
  {
    code: "d115",
    title: "Słuchanie",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Wraz z wiekiem mogą pogarszać się funkcje narządu słuchu (np. podwyższenie progu słyszenia, utrudnione rozpoznawanie i rozróżnianie dźwięków, szumy uszne). Uzyskany wynik w strefie żółtej lub czerwonej wskazuje, że część pracowników ma pogorszone funkcje narządu słuchu, w związku z tym może to wpływać na ich funkcjonowanie bez względu na rodzaj wykonywanej pracy szczególnie w relacjach z innymi osobami oraz w sytuacji zagrożenia bezpieczeństwa (alarmowe sygnały dźwiękowe). Można rozważyć przyspieszenie badania profilaktycznego u lekarza medycyny pracy."
  },
  {
    code: "d175",
    title: "Rozwiązywanie problemów",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "W sytuacji pracy osób w starszym wieku mogą pojawić się trudności w rozwiązywaniu problemów. Trudności te mogą mieć różne przyczyny i konsekwencje, np. z powodu obawy przed oceną ze strony przełożonych lub niejasności sytuacji problemowej. Osoby z tymi trudnościami mogą w większym stopniu odczuwać obciążenie psychiczne związane z koniecznością podejmowania decyzji, pracy z klientem i wykonywania zadań pod presją czasu. Pracodawca może sprawdzić, czy modyfikacja określonych warunków pracy (np. zbyt trudnych zadań, konfliktu roli, czy braku wsparcia ze strony kolegów i przełożonych) nie poprawi pracownikowi umiejętności rozwiązywania problemów."
  },
  {
    code: "d177",
    title: "Podejmowanie decyzji",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "W sytuacji pracy osób w starszym wieku mogą pojawić się trudności w podejmowaniu decyzji. Trudności te mogą mieć różne przyczyny i konsekwencje, np. z powodu niepewności co do trafności decyzji lub niejasności sytuacji decyzyjnej. Osoby z tymi trudnościami mogą w większym stopniu odczuwać obciążenie psychiczne związane z koniecznością podejmowania decyzji, pracy z klientem i wykonywania zadań pod presją czasu. Pracodawca może sprawdzić czy wsparcie poprzez zwiększenie poczucia kontroli nad sytuacją lub zwiększenie wsparcia informacyjnego ze strony przełożonych może poprawić zdolność podejmowania decyzji u pracownika."
  },
  {
    code: "d240",
    title: "Radzenie sobie ze stresem i innymi obciążeniami psychicznymi",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Osoby starsze są bardziej narażone na stres w sytuacjach zadaniowych, które wymagają elastyczności i otwartości na zmiany. Stres może też wynikać z odczuwanej  presji czasu, konieczności podejmowania trudnych decyzji lub napiętych relacji ze współpracownikami lub klientami. Można przypuszczać, że osoby posiadające trudności w radzeniu sobie ze stresem mogą mieć trudności w wykonywaniu zadań w sytuacji pracy z klientem, szczególnie gdy mogą występować sytuacje konfliktowe. Pracodawca może ocenić, czy wprowadzenie dodatkowych działań wspierających (np. redukcja zadań potencjalnie emocjonujących, szkolenia z zakresu obsługi trudnego klienta, itp.) może wpłynąć na poprawę radzenia sobie ze stresem."
  },
  {
    code: "d360",
    title: "Używanie urządzeń i technik służących do porozumiewania się",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Masowość zastosowania urządzeń elektronicznej w życiu zawodowym i prywatnym wymusza ciągłe podążanie za nowymi rozwiązaniami i przyswajanie nowych umiejętności. Może być to szczególnie trudne dla starszych pracowników. Wykluczenie cyfrowe może wynikać z braku dostępności do urządzeń lub oprogramowania (aplikacji) ale również wynikać z braku umiejętności i tzw. kompetencji cyfrowych. W takich sytuacjach Pracodawca może wprowadzać szkolenia obsługi oprogramowania i urządzeń wykorzystywanych w pracy, zarówno do komunikowania się z innymi pracownikami lub klientami, jak i do realizacji zadań zawodowych."
  },
  {
    code: "d415",
    title: "Utrzymywanie pozycji ciała",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Wraz z wiekiem może pogarszać się stabilność postawy a także obserwowane jest szybsze odczuwanie zmęczenia podczas wysiłku o charakterze statycznym. Wpływa to na możliwość długotrwałego i stabilnego utrzymywania pozycji ciała (np. stanie). Organizacja pracy powinna uwzględniać wykonywanie czynności różnorodnych wymagających zmiany pozycji ciała. Można rozważyć przyspieszenie badania profilaktycznego u lekarza medycyny pracy."
  },
  {
    code: "d430",
    title: "Podnoszenie i przenoszenie przedmiotów",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: `Rekomendacje (gdy przynajmniej dla 1 z pracowników wynik jest żółty lub czerwony):  
    U osób starszych może zmniejszać się masa i siła mięśni, ruchomość stawów oraz sprawność lokomocyjna. Czynniki te mogą wpływać na możliwość wykonywania ręcznych prac transportowych związanych z dużym wydatkiem energetycznym. Organizacja pracy związana z podnoszeniem i przenoszeniem przedmiotów powinna uwzględniać kilka głównych zasad:
    <ol>
      <li>należy stosować sprzęt pomocniczy w celu zmniejszenia obciążenia układu mięśniowo-szkieletowego, a w szczególności kręgosłupa</li>
      <li>należy unikać dużych skrętów i niepotrzebnego pochylania tułowia</li>
      <li>należy dążyć do tego, aby pozycja ciała była możliwie najbardziej zbliżona do naturalnej</li>
      <li>najkorzystniejszą wysokością, z której podnoszone są przedmioty, jest wysokość bioder pracownika, wysokość ta powinna być dopasowana indywidualnie</li>
      <li>przedmioty przenoszone powinny mieć uchwyty</li>
      <li>przedmioty o dużych rozmiarach i dużej masie powinny być przenoszone zespołowo z zachowaniem wszystkich ww. środków bezpieczeństwa i zaleceń dotyczących sposobów podnoszenia i przenoszenia przedmiotów.</li>
    </ol>
    `
  },
  {
    code: "d440",
    title: "Precyzyjne używanie ręki",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "U niektórych osób może zmniejszać się zdolność precyzyjnego używania ręki, co może wynikać z dolegliwości układu mięśniowo-szkieletowego jak i z pogorszenie funkcji narządu wzroku. W przypadku wykonywania czynności pracy wymagających precyzyjnego używania ręki należy zapewnić pracownikom lepszą stabilność postawy, wysokość płaszczyzny roboczej, która powinna być wyższa niż podczas pozostałych prac również ze względu na ewentualną szczegółową kontrolę wzrokową, czy możliwość zmniejszenia tempa pracy"
  },
  {
    code: "d445",
    title: "Używanie rąk i ramion",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: `Uzyskany wynik w strefie żółtej lub czerwonej wskazuje, że część pracowników ma pogorszone możliwości w zakresie czynności wymagających używania rąk i ramion, co może wynikać ze zmniejszonej siły mięśni oraz zakresu ruchomości stawów. W przypadku wykonywania tego typu czynności pracy istotnym jest, aby znaleźć sposoby zmniejszenia obciążenia układu ruchu. Rekomendowane w tym przypadku jest: 
    <ul>
      <li>ergonomiczne dostosowywanie stanowisk pracy do rodzaju i charakteru wykonywanych czynności</li>
      <li>odpowiednie dobranie narzędzi dodatkowych</li>
      <li>organizacja czasu i tempa pracy</li>
      <li>rotacja na stanowiskach pracy</li>
      <li>przerwy na odpoczynek</li>
    </ul>
    `
  },
  {
    code: "d450",
    title: "Chodzenie",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "Uzyskany wynik w strefie żółtej lub czerwonej wskazuje, że część pracowników ma pogorszone możliwości w zakresie chodu. Zaburzenie wzorca chodu może wskazywać na problemy z przemieszczaniem się na nierównym terenie, na dłuższe odległości, ograniczenie możliwości przyjmowania pozycji wymuszonych, takich jak pozycja z pochylonym tułowiem, kuczna czy klęczna oraz pracy na wysokości wymagającej przemieszczania się na podwyższeniu (np. drabina, podest, rusztowanie). Rekomendowane jest stanowisko z możliwością swobodnej zmiany pozycji, w zależności od potrzeb pracownika. Jeśli pracownicy wykonują pracę na wysokości, to należy rozważyć stosowanie urządzeń wspomagających (np. podnośniki koszowe, podesty ruchome)."
  },
  {
    code: "d720",
    title: "Złożone kontakty międzyludzkie",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie.",
    badResult: "W grupie pracowników starszych mogą pojawić się trudności w sytuacjach złożonych kontaktów międzyludzkich polegających na utrzymywaniu i kontrolowaniu wzajemnych kontaktów z innymi ludźmi, w sposób odpowiedni do danej sytuacji i akceptowany społecznie. Mogą one wynikać z doświadczania silnego lęku w sytuacjach społecznych  jak i poczucia wyalienowania z grupy pracowniczej. Można przypuszczać, że osoby z takimi problemami mogą mieć trudności w wykonywaniu zadań zarówno  w sytuacji pracy z klientem jak i w relacjach ze współpracownikami. Pracodawca może ocenić, czy wprowadzenie dodatkowych działań wspierających (np. redukcja zadań potencjalnie emocjonujących, szkolenia z zakresu obsługi trudnego klienta, wsparcie od współpracowników itp.) może wpłynąć na poprawę relacji społecznych i ułatwić aktywność w tych relacjach."
  },
  {
    code: "e1101",
    title: "Leki",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie lub ułatwia im to funkcjonowanie.",
    badResult: "Wśród pracowników uczestniczących w badaniu, są osoby, które oceniły, że leki utrudniają im funkcjonowanie. Można rozważyć przyspieszenie badania profilaktycznego u lekarza medycyny pracy."
  },
  {
    code: "e125",
    title: "Produkty i technologie (służące do porozumiewania się, stosowane w miejscu zatrudnienia)",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie lub ułatwia im to funkcjonowanie.",
    badResult: "Wśród pracowników uczestniczących w badaniu, są osoby, które oceniły, że produkty i technologie utrudniają im funkcjonowanie. W takich sytuacjach Pracodawca może wprowadzać szkolenia obsługi oprogramowania i urządzeń wykorzystywanych w pracy, zarówno do komunikowania się z innymi pracownikami lub klientami, jak i do realizacji zadań zawodowych."
  },
  {
    code: "e135",
    title: "Produkty i technologie (służące do porozumiewania się, stosowane w miejscu zatrudnienia)",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie lub ułatwia im to funkcjonowanie.",
    badResult: "Wśród pracowników uczestniczących w badaniu, są osoby, które oceniły, że produkty i technologie utrudniają im funkcjonowanie. W takich sytuacjach Pracodawca może wprowadzać szkolenia obsługi oprogramowania i urządzeń wykorzystywanych w pracy, zarówno do komunikowania się z innymi pracownikami lub klientami, jak i do realizacji zadań zawodowych."
  },  
  {
    code: "e310",
    title: "Najbliższa rodzina, znajomi, rówieśnicy, koledzy, sąsiedzi i członkowie lokalnej społeczności",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie lub ułatwia im to funkcjonowanie.",
    badResult: "Wśród pracowników uczestniczących w badaniu, są osoby, które oceniły, że niektóre relacje w życiu prywatnym utrudniają im funkcjonowanie. Może to powodować zjawisko konfliktu roli i wynikające z tego obciążenia psychiczne."
  },
  {
    code: "e325",
    title: "Najbliższa rodzina, znajomi, rówieśnicy, koledzy, sąsiedzi i członkowie lokalnej społeczności",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie lub ułatwia im to funkcjonowanie.",
    badResult: "Wśród pracowników uczestniczących w badaniu, są osoby, które oceniły, że niektóre relacje w życiu prywatnym utrudniają im funkcjonowanie. Może to powodować zjawisko konfliktu roli i wynikające z tego obciążenia psychiczne."
  },  
  {
    code: "e330",
    title: "Ludzie posiadający władzę (zwierzchnicy) oraz podwładni",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie lub ułatwia im to funkcjonowanie.",
    badResult: "Wśród pracowników uczestniczących w badaniu, są osoby, które oceniły, że niektóre relacje w pracy utrudniają im funkcjonowanie. Może to powodować większe obciążenie psychiczne pracowników lub wypalenie zawodowe. W takich sytuacjach Pracodawca może wprowadzać warszaty integrujące pracowników lub szkolenia z zakresu skutecznej komunikacji i znaczenia wsparcia społecznego."
  },
  {
    code: "e335",
    title: "Ludzie posiadający władzę (zwierzchnicy) oraz podwładni",
    goodResult: "Pracownicy uczestniczący w badaniu ocenili, że nie mają problemów w tym zakresie lub ułatwia im to funkcjonowanie.",
    badResult: "Wśród pracowników uczestniczących w badaniu, są osoby, które oceniły, że niektóre relacje w pracy utrudniają im funkcjonowanie. Może to powodować większe obciążenie psychiczne pracowników lub wypalenie zawodowe. W takich sytuacjach Pracodawca może wprowadzać warszaty integrujące pracowników lub szkolenia z zakresu skutecznej komunikacji i znaczenia wsparcia społecznego."
  }
]