import {gql} from "@apollo/client"

// export const ANSWERS_LIST = gql`
//     query answersList($userId: UUID!){
//       answersList: examAnswersListList(userId: $userId){
//         answers
//         category
//         additionalInformation
//       }
//       user: userExam(userId: $userId) {
//         userId
//         fullName
//         finishedExamAt
//       }
//     }
// `

export const ANONYMOUS_EXAM = gql`
  query anonymousExam($examId: UUID!){
    anonymousExam(examId: $examId) {
      examinedUser {
        fullName
      }
    }
  }
`

export const CREATE_EXAM = gql`
  mutation StartExam($input: StartExamInput!) {
    startExam(input: $input) {
      clientMutationId
    }
  }
`