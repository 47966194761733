import { gql } from '@apollo/client';

export const EXAM_GROUP_USER_QUERY = gql`
  query ExamGroupuser($tenantId: UUID!) {
    connection: tenant(tenantId: $tenantId) {
      examGroups {
        edges {
        node {
            name
            examGroupId
          }
        }
      }
    }
  }
`