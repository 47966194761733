import { useMutation } from '@apollo/client';
import { FormControl, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import { SAVE_FACTOR_ANSWER } from '../../examComponents/examQuestionCard/ExamQuestionCard.mutation';
import { useButtonsControllerStyles } from '../buttonsController/ButtonsController.styles';

interface ButtonGroupControllerProps {
  name: string;
  examId: string
}

const ButtonGroupController: FC<ButtonGroupControllerProps> = ({
  name,
  examId
}) => {
  const { control } = useFormContext()
  const classes = useButtonsControllerStyles()
  const [saveAnswer,{loading}] = useMutation(SAVE_FACTOR_ANSWER)
  const options = [
    {
      value: "1",
      label: "1 - minimalne chęci"
    },
    {
      value: "2",
      label: "2"
    },
    {
      value: "3",
      label: "3"
    },
    {
      value: "4",
      label: "4"
    },
    {
      value: "5",
      label: "5"
    },
    {
      value: "6",
      label: "6"
    },
    {
      value: "7",
      label: "7"
    },
    {
      value: "8",
      label: "8"
    },
    {
      value: "9",
      label: "9"
    },
    {
      value: "10",
      label: "10 - maksymalne chęci"
    }
  ]

  const sendAnswer = async (value: Array<string | null>, questionNumber: string) => {
    try {
      await saveAnswer({
        variables: {
          answers: value,
          questionNumber: Number(questionNumber),
          examId
        }
      })
    } catch(e) {
      console.error(e)
    }
  }

  return (
    <Controller 
      name={name}
      control={control}
      rules={{required: true}}
      render={({
        field: {
          value,
          onChange,
        },
        fieldState: {
          error,
          invalid
        }
      }) => (
        <FormControl
          error={!!error?.message}
        >
          <ToggleButtonGroup
            value={value}
          >
            {options.map(option => (
              <ToggleButton
                value={option.value}
                onClick={() => {
                  onChange(option.value)
                  sendAnswer([option.value], name)
                }}
                disabled={loading}
              >
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          {error && (
              <Typography
                variant={"subtitle1"}
                className={classes.errorText}
              >
                Odpowiedź jest wymagana
              </Typography>
            )}
        </FormControl>
      )}
    />
  )
}

export default ButtonGroupController