import React from 'react'
import AdminPageCard from '../components/adminPageCard/AdminPageCard.component';
import { Box } from '@material-ui/core';
import ResultForEmployerForm from '../components/resultForEmployer/resultForEmployerForm/ResultForEmployerForm';

const ResultsForEmployer = () => {
  return (
    <AdminPageCard
      title='Karta wyników dla Pracodawcy'
    >
      <Box>
        <ResultForEmployerForm/>
      </Box>
    </AdminPageCard>
  )
}

export default ResultsForEmployer