import React, {FC, useEffect} from "react"
import {Box, TableBody, TableHead, Typography} from "@material-ui/core"
import {USERS_LIST_QUERY} from "../../user/usersList/UsersList.query"
import useRelayConnection from "../../../hooks/useRelayConnection"
import {ExaminedPersonUserItemNode} from "../../../store/user/user.types"
import {usePaginationParams} from "../../../hooks/useQueryParams"
import TableWithPagination from "../../common/tableWithPagination/TableWithPagination.component"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import {useUsersListStyles} from "../../user/usersList/UsersList.styles"
import {UserRole} from "../../user/userRole/UserRole.types";
import {useSelector} from "react-redux";
import {selectUser} from "../../../store/user/user.selectors";
import TableCellWithFilter from "../../common/tableCellWithFilter/TableCellWithFilter.component"
import {StringParam, useQueryParam} from "use-query-params"
import TestedPersonsActions from "../testedPersonsActions/TestedPersonsActions.component";
import {FilterSelectType} from "../../common/tableCellWithFilter/TableCellWithFilter.types";
import AddUserPopup from "../../user/addUserPopup/AddUser.component";
import ExamsManagementIcons from "../examsManagementIcons/ExamsManagementIcons.component";
import removeOrphans from "../../../utils/removeOrphans";

interface UsersListProps {
  isOpenAddUserPopup: boolean;
  onCloseAddUserPopup(): void;
}

const TestedPersonsList: FC<UsersListProps> = ({isOpenAddUserPopup, onCloseAddUserPopup}) => {
  const classes = useUsersListStyles()
  const currentUser = useSelector(selectUser)

  const [fullNameFilter, setFullNameFilter] = useQueryParam("fullName", StringParam)
  const [professionTypeFilter, setProfessionTypeFilter] = useQueryParam("occupationTypes", StringParam)
  const [guardiaFilter, setGuardianFilter] = useQueryParam("guardianId", StringParam)
  const [tenantIdFilter, setTenantIdFilter] = useQueryParam("tenantId", StringParam)

  const filters = {
    userRole: { equalTo: UserRole.EXAMINED_PERSON },
    archived: { equalTo: false },
    fullName: fullNameFilter ? { likeInsensitive: fullNameFilter } : undefined,
    occupationTypeId: professionTypeFilter ? { equalTo: professionTypeFilter } : undefined,
    guardianId: guardiaFilter ? { equalTo: guardiaFilter } : undefined,
    tenantsIds: tenantIdFilter ? {anyEqualTo: tenantIdFilter}: undefined
  }

  const {tableProps, refetch, loading} = useRelayConnection<ExaminedPersonUserItemNode>(USERS_LIST_QUERY, {
    variables: {
      filter: filters,
      orderBy: ['REGISTERED_AT_DESC']
    },
    pagination: usePaginationParams()
  })

  useEffect(() => {
    refetch()
  }, [fullNameFilter])

  return (
    <Box>

      <AddUserPopup
        open={isOpenAddUserPopup}
        refetch={refetch}
        onClose={onCloseAddUserPopup}
      />

      <TableWithPagination {...tableProps}>
        <TableHead>
          <TableRow>
            <TableCellWithFilter
              defaultValue={fullNameFilter}
              label="Imię i nazwisko"
              disabled={loading}
              onFilterSubmit={(val) => setFullNameFilter(val)}
            >
              <Typography className={classes.fieldName}>Imię i nazwisko</Typography>
            </TableCellWithFilter>

            <TableCell>
              <Typography className={classes.fieldName}>E-mail</Typography>
            </TableCell>

            <TableCellWithFilter
              defaultValue={professionTypeFilter}
              label="Typ zawodu"
              disabled={loading}
              select={FilterSelectType.TESTED_PERSON_OCCUPATION}
              onFilterSubmit={(val) => setProfessionTypeFilter(val)}
            >
              <Typography className={classes.fieldName}>Typ zawodu</Typography>
            </TableCellWithFilter>

            <TableCellWithFilter
              defaultValue={guardiaFilter}
              label="Opiekun"
              disabled={loading}
              select={FilterSelectType.GUARDIANS}
              onFilterSubmit={(val) => setGuardianFilter(val)}
            >
              <Typography className={classes.fieldName}>Opiekun</Typography>
            </TableCellWithFilter>

            <TableCellWithFilter
              defaultValue={tenantIdFilter}
              label="Podmiot"
              disabled={loading}
              select={FilterSelectType.TENANTS}
              onFilterSubmit={(val) => setTenantIdFilter(val)}
            >
              <Typography className={classes.fieldName}>Podmiot</Typography>
            </TableCellWithFilter>

            {currentUser?.userRole === UserRole.GUARDIAN &&
              <TableCell align={'right'} width={"180px"}>
                <Typography className={classes.fieldName}>Zarządzanie badaniami</Typography>
              </TableCell>
            }

            <TableCell>
              <Typography className={classes.fieldName} align={"right"}>Akcje</Typography>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          <>
            { tableProps.dataSource.map((dataItem: ExaminedPersonUserItemNode) => (
              <TableRow key={dataItem.email}>
                <TableCell>
                  {dataItem.name} {dataItem.surname}
                </TableCell>

                <TableCell>
                  {dataItem.email}
                </TableCell>

                <TableCell>
                  <Box className={classes.occupationType}>
                    <Typography
                      className={classes.occupationTypeText}
                      dangerouslySetInnerHTML={{ __html: removeOrphans(dataItem.occupationType.name) }}
                    />
                  </Box>
                </TableCell>

                <TableCell>
                  { dataItem.guardian.name } { dataItem.guardian.surname }
                </TableCell>

                <TableCell>
                  {dataItem.tenantsList?.length && dataItem.tenantsList[0].name}
                </TableCell>

                {currentUser?.userRole === UserRole.GUARDIAN &&
                  <TableCell align={"right"}>
                    <ExamsManagementIcons
                      userId={dataItem.userId}
                      // finishedExam={dataItem.finishedExamAt}//todo
                    />
                  </TableCell>
                }

                <TableCell align={"right"}>
                  <TestedPersonsActions
                    user={dataItem}
                    refetch={refetch}
                  />
                </TableCell>

              </TableRow>
            ))}
          </>
        </TableBody>
      </TableWithPagination>
    </Box>
  )
}

export default TestedPersonsList
