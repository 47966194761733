import {gql} from '@apollo/client'

export const SAVE_ANSWER = gql`
    mutation saveAnswer($examId: UUID!, $category: String!, $answers: [String]!){
      saveAnswer(input: {examId: $examId, answers: $answers, category: $category}){
        clientMutationId
      }
    }
`

export const SAVE_FACTOR_ANSWER = gql`
  mutation saveFactorAnswer($examId: UUID!, $questionNumber: Int!, $answers: [String]!) {
    saveFactorAnswer(input: {examId: $examId, answers: $answers, questionNumber: $questionNumber}){
        clientMutationId
    }
  }
`

export const FINISH_EXAM = gql`
    mutation finishExam($examId: UUID!){
      finishExam(input: {examId: $examId}){
        clientMutationId
        string
      }
    }
`