import { gql } from "@apollo/client"

export const EXAM_GROUP_LIST_QUERY = gql`
    query ExamGroups($filter: ExamGroupFilter) {
        connection: examGroups( filter: $filter) {
            edges {
                node {
                  name
                  examGroupId
                }
            }
        }
    }
`;