import React, { FC, useState } from "react"
import {Button, ButtonGroup, Tooltip} from "@material-ui/core"
import { useExamGroupsListActionsStyles } from "./ExamGroupsListActions.styles"
import EditExamGroupPopup from "../editExamGroupPopup/EditExamGroup.component"
import ArchiveExamGroupPopup from "../archiveExamGroupPopup/ArchiveExamGroup.component"
import {EditOutlined, ArchiveOutlined} from '@material-ui/icons'
import {ExamGroupItemNode} from "../examGroup.types";

interface ExamGroupsListActionsProps {
  examGroup: ExamGroupItemNode;
  refetch(): void;
}

const ExamGroupsListActions: FC<ExamGroupsListActionsProps> = ({ examGroup, refetch }) => {
  const classes = useExamGroupsListActionsStyles()

  const [isOpenEditExamGroupPopup, setIsOpenEditExamGroupPopup] = useState<boolean>(false)
  const [isOpenArchiveExamGroupPopup, setIsOpenArchiveExamGroupPopup] = useState<boolean>(false)

  return (
    <ButtonGroup>
      <Tooltip title={'Edytuj dane grupy do badań'}>
        <Button
          className={classes.button}
          onClick={() => setIsOpenEditExamGroupPopup(true)}
        >
          <EditOutlined className={classes.icon}/>
        </Button>
      </Tooltip>

      <Tooltip title={'Archiwizuj grupę do badań'}>
        <Button
          className={classes.button}
          onClick={() => setIsOpenArchiveExamGroupPopup(true)}
        >
          <ArchiveOutlined className={classes.icon}/>
        </Button>
      </Tooltip>

      <ArchiveExamGroupPopup
        examGroup={examGroup}
        open={isOpenArchiveExamGroupPopup}
        refetch={refetch}
        onClose={() => setIsOpenArchiveExamGroupPopup(false)}
      />

      <EditExamGroupPopup
        examGroup={examGroup}
        open={isOpenEditExamGroupPopup}
        refetch={refetch}
        onClose={() => setIsOpenEditExamGroupPopup(false)}
      />
    </ButtonGroup>
  )
}

export default ExamGroupsListActions
