import { Box, Divider, Tooltip, Typography } from '@material-ui/core';
import React, { FC } from 'react'
import { useRecommendationCardStyles } from './RecommendationCard.styles';
import { recommendations } from './recommendations';

interface RecommendationCardProps {
  code: string;
  red: number
  yellow: number;
  green: number
}

const RecommendationCard: FC<RecommendationCardProps> = ({
  code,
  red,
  yellow,
  green
}) => {
  const classes = useRecommendationCardStyles()
  const recommendation = recommendations.find(item => item.code === code.toLowerCase())
  return (
    <div className='no-break-inside'>
      <Typography variant='h5'>
        {code} - {recommendation?.title}
      </Typography>

      <div className={classes.bar}>
        <Tooltip title={`${green * 100}%`}>
          <div className={classes.green} style={{width: `${green * 100}%`}}/>
        </Tooltip>
        <Tooltip title={`${yellow * 100}%`}>
          <div className={classes.yellow} style={{width: `${yellow * 100}%`}}/>
        </Tooltip>
        <Tooltip title={`${red * 100}%`}>
          <div className={classes.red} style={{width: `${red * 100}%`}}/>
        </Tooltip>
      </div>

      <Typography dangerouslySetInnerHTML={{__html: (green === 1 ? recommendation?.goodResult : recommendation?.badResult) ?? ""}}/>

      <Box marginY={2}>
        <Divider/>
      </Box>
    </div>
  )
}

export default RecommendationCard