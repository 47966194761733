import { gql } from "@apollo/client"

export const USER_QUERY = gql`
    query GetUserById($userId: UUID!) {
        user: user(userId: $userId) {
            userId
            email
            name
            surname
            userRole
            archived
            fullName
            tenantsList {
                name
                tenantId
            }
            occupationType {
              name
              occupationTypeId
            }
            workplaceThreats
        }
    }
`;