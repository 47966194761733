import React, {FC} from "react"
import { RoutePath } from "./Routes.types"
import { Switch, Route } from "react-router-dom"
import PrivateRoute from "./PrivateRoute.component"
import withBaseLayout from "../hoc/withBaseLayout"
import withAdminLayout from "../hoc/withAdminLayout"
import withTestedPersonLayout from "../hoc/withTestedPersonLayout"
import Error403Page from "../pages/errors/Error403Page"
import Error404Page from "../pages/errors/Error404Page"
import ServerErrorPage from "../pages/errors/ServerErrorPage"
import LoginPage from "../pages/auth/login/LoginPage"
import ForgetPasswordPage from "../pages/auth/forgetPassword/ForgetPasswordPage"
import ResetPasswordPage from "../pages/auth/resetPassword/ResetPasswordPage"
import testedPersonRoutes from "./routesList/TestedPerson.routes"
import { RouteListItem } from "./routesList/RouteList.types"
import adminRoutes from "./routesList/Admin.routes"
import RoutesController from "./RoutesController"
import LoaderBox from "../components/common/loaderBox/LoaderBox.component"
import ExamPage from "../pages/ExamPage"
import ExamResultPage from '../pages/ExamResultPage';
import ExamResultsFormEmployer from '../pages/ExamResultsFormEmployer';
import FinishedExamWithPdfPage from '../pages/FinishedExamWithPdfPage';
import FinishExamWithoutPdfPage from '../pages/FinishExamWithoutPdfPage';

const Routes: FC = () => {
  return (
    <RoutesController>
      <Switch>
        { adminRoutes.map((adminRouteConfig: RouteListItem) =>
          <PrivateRoute
            key={adminRouteConfig.path}
            path={adminRouteConfig.path}
            exact
            routePermission={adminRouteConfig.permission}
            component={withAdminLayout(adminRouteConfig.component)}
          />
        )}

        { testedPersonRoutes.map((testedPersonRouteConfig: RouteListItem) =>
          <PrivateRoute
            key={testedPersonRouteConfig.path}
            path={testedPersonRouteConfig.path}
            exact
            routePermission={testedPersonRouteConfig.permission}
            component={withTestedPersonLayout(testedPersonRouteConfig.component)}
          />
        )}

        <Route exact path={RoutePath.LOGIN} component={withBaseLayout(LoginPage)}/>
        <Route exact path={RoutePath.FORGOT_PASSWORD} component={withBaseLayout(ForgetPasswordPage)}/>
        <Route exact path={RoutePath.RESET_PASSWORD} component={withBaseLayout(ResetPasswordPage)}/>

        <Route exact path={RoutePath.EXAM} component={ExamPage}/>
        <Route exact path={RoutePath.BASE} component={withBaseLayout(LoaderBox)}/>
        <Route exact path={RoutePath.EXAM_RESULT} component={ExamResultPage}/>
        <Route exact path={RoutePath.BATCH_RESULTS} component={ExamResultsFormEmployer}/>
        <Route exact path={RoutePath.FINISED_EXAM_WITH_PDF} component={withBaseLayout(FinishedExamWithPdfPage)}/>
        <Route exact path={RoutePath.FINISED_EXAM_WITHOUT_PDF} component={withBaseLayout(FinishExamWithoutPdfPage)}/>
        <Route path={RoutePath.ERROR_404} component={withBaseLayout(Error404Page)}/>
        <Route path={RoutePath.ERROR_403} exact component={withBaseLayout(Error403Page)}/>
        <Route path={RoutePath.SERVER_ERROR} exact component={withBaseLayout(ServerErrorPage)}/>
        <Route path={RoutePath.FALLBACK} component={withBaseLayout(Error404Page)}/>
      </Switch>
    </RoutesController>
  )
}

export default Routes
