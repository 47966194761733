import React, { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './datepickerControler.css'
import pl from "date-fns/locale/pl";
import { FormControl } from '@material-ui/core';

registerLocale("pl", pl)

interface DatePickerControllerProps {
  name: string
  label?: string
  disabled?: boolean
}

const DatePickerController: FC<DatePickerControllerProps> = ({ 
  name,
  label,
  disabled
}) => {
  const form = useFormContext()
  return (
    <Controller
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormControl
          component="fieldset"
          error={fieldState.invalid}
        >
          <DatePicker
            locale={"pl"}
            placeholderText={label || 'Wybierz datę'}
            onChange={(date: Date) => field.onChange(date)}
            selected={field.value}
            disabled={disabled}
            dateFormat='dd/MM/yyyy'
          />

          {fieldState.error && (
            <p>{fieldState.error.message}</p>
          )}
        </FormControl>
      )}
    />
  )
}

export default DatePickerController