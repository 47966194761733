import {FC} from 'react'
import {Alert} from "@material-ui/lab";
import { ColorLight} from "../examUtils/exam.types";
import {getGeneralDescription, getAlertSeverity} from "./ExamResultGeneralDescription.utils";
import {Box, Typography} from "@material-ui/core";
import {useExamResultGeneralDescriptionStyles} from "./ExamResultGeneralDescription.styles";
import clsx from "clsx";
import { ResultData } from '../../../pages/ExamResultPage';

interface ExamResultGeneralDescriptionProps {
  data: ResultData
}

const ExamResultGeneralDescription: FC<ExamResultGeneralDescriptionProps> = ({
  data
}) => {
  const classes = useExamResultGeneralDescriptionStyles();

  const getGeneralColor = (): ColorLight => {
    if(data.red.length > 0) {
      return ColorLight.RED
    } else if(data.yellow.length > 0) {
      return ColorLight.YELLOW
    } else {
      return ColorLight.GREEN
    }
  }

  const generalColor = getGeneralColor()

	return (
    <Box marginY={5}>
      <Typography
        variant={"h5"}
        component={"span"}
        className={clsx(classes.title, "print-no-margin")}
      >
        Ogólne podsumownanie badania
      </Typography>

      <Alert severity={getAlertSeverity(generalColor)}>
        <Typography
          variant={"body1"}
          component={"div"}
          align={"justify"}
          className={"print-text-normal"}
        >
          { getGeneralDescription(generalColor) }
        </Typography>
      </Alert>
    </Box>
  )
}

export default ExamResultGeneralDescription