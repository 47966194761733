import {RoutePath} from "../Routes.types"
import {RouteListItem} from "./RouteList.types"
import {UserRole} from "../../components/user/userRole/UserRole.types"

import ReportsPage from "../../pages/ReportsPage"
import TenantsPage from "../../pages/TenantsPage"
import UsersPage from "../../pages/UsersPage"
import TestedPersonsPage from "../../pages/TestedPersonsPage";
import ExamGroupsPage from "../../pages/ExamGroups";

import {BarChartOutlined, GroupOutlined, PeopleOutlined, PersonOutline, AssignmentOutlined} from "@material-ui/icons";
import ResultsForEmployer from '../../pages/ResultsForEmployer';

const adminRoutes: RouteListItem[] = [
  {
    path: RoutePath.USERS,
    component: UsersPage,
    title: "Użytkownicy",
    permission: [UserRole.ADMIN],
    icon: PersonOutline,
  },
  {
    path: RoutePath.TESTED_PERSONS,
    component: TestedPersonsPage,
    title: "Lista osób badanych",
    permission: [UserRole.ADMIN, UserRole.GUARDIAN],
    icon: GroupOutlined
  },
  {
    path: RoutePath.EXAM_GROUPS,
    component: ExamGroupsPage,
    title: "Lista grup do badań",
    permission: [UserRole.GUARDIAN],
    icon: AssignmentOutlined
  },
  {
    path: RoutePath.RESULTS_FOR_EMPLOYER,
    component: ResultsForEmployer,
    title: "Karta wyników dla Pracodawcy",
    permission: [UserRole.GUARDIAN],
    icon: BarChartOutlined,
  },
  {
    path: RoutePath.TENANTS,
    component: TenantsPage,
    title: "Podmioty",
    permission: [UserRole.ADMIN],
    icon: PeopleOutlined,
  },
  {
    path: RoutePath.REPORTS,
    component: ReportsPage,
    title: "Raporty",
    permission: [UserRole.ADMIN, UserRole.GUARDIAN],
    icon: BarChartOutlined,
  }
]

export default adminRoutes
