import React, {FC, useEffect} from "react"
import {Box, Grid} from "@material-ui/core"
import Popup from "../../common/popup/Popup.component"
import {FormProvider, useForm} from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import {useMutation} from "@apollo/client";
import {EDIT_EXAM_GROUP} from "./EditExamGroup.mutation"
import {yupResolver} from "@hookform/resolvers/yup";
import {editExamGroupValidationSchema} from "./EditExamGroup.validation";
import TenantsSelect from "../../tenants/tenantsSelect/TenantsSelect.component";
import {TenantSelectItem} from "../../tenants/tenantsSelect/TenantsSelect.types";
import {useSelector} from "react-redux";
import {selectCurrentTenantId} from "../../../store/user/user.selectors";
import {ExamGroupDataState} from "../examGroup.types";

interface EditExamGroupPopupProps {
  examGroup: ExamGroupDataState;
  open: boolean;
  onClose(): void;
  refetch?(): void;
}

const EditExamGroupPopup: FC<EditExamGroupPopupProps> = ({examGroup, open, refetch, onClose}) => {
  const tenantsId = useSelector(selectCurrentTenantId)

  const [editExamGroup, {loading}] = useMutation(EDIT_EXAM_GROUP)

  const form = useForm({
    mode: "all",
    shouldUnregister: true,
    resolver: yupResolver(editExamGroupValidationSchema),
    defaultValues: {
      name: examGroup?.name,
      tenant: examGroup?.tenant.tenantId,
    }
  })

  useEffect(() => {
    if (open) {
      form.setValue("name", examGroup?.name)
      form.setValue("tenant", examGroup?.tenant.tenantId)
    }
  }, [open])

  const handleClose = () => {
    onClose()
    form.reset()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    try {
      await editExamGroup({
        variables: {
          input: {
            examGroupId: examGroup?.examGroupId,
            patch: {
              examGroupId: examGroup?.examGroupId,
              name: values.name,
              tenantId: values.tenant,
            }
          }
        }
      })

      if (refetch) {
        await refetch()
      }
      handleClose()
    } catch (e) {
      console.error(e)
    }
  })

  if (!examGroup) return null

  return (
    <Popup
      open={open}
      onClose={handleClose}
      title={"Edytuj grupę badań"}
      actionButtonText={"Zapisz"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading,
      }}
      desktopWidth={"800px"}
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item>
                <TextFieldController
                  name="name"
                  label={'Nazwa'}
                  maxCharacters={100}
                  isRequired
                  trimStart
                  mb
                  disabled={loading}
                />
              </Grid>

              <Grid item>
                <TenantsSelect
                  name="tenant"
                  label={'Podmiot'}
                  isRequired
                  multiple={false}
                  filterBy={(tenant: TenantSelectItem) => {
                    return tenantsId.includes(tenant.tenantId)
                  }}
                  mb
                  disabled={loading}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </Box>
    </Popup>
  )
}

export default EditExamGroupPopup
