import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useRecommendationCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      display: "flex",
      maxWidth: "400px",
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden",
      margin: "16px 0"
    },
    red: {
      backgroundColor: "#c0392b",
      height: theme.spacing(2)
    },
    yellow: {
      backgroundColor: "#f1c40f",
      height: theme.spacing(2)
    },
    green: {
      backgroundColor: "#2ecc71",
      height: theme.spacing(2)
    },
  }),
)