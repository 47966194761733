type EmployeeRecommendationTypes = {
  code: string,
  colors: {
    green: string,
    yellow: string,
    red: string,
  }
}

export const employeeRecommendations: EmployeeRecommendationTypes[] = [
  {
    code: "B130",
    colors: {
      green: `Ocena wskazuje na prawidłowe funkcjonowanie w zakresie ogólnej aktywności, która pobudza do działania oraz kontroli realizacji potrzeb, w tym korzystania z różnych używek, jak tytoń czy alkohol. Nie występuje u Pana/Pani brak napędu do działania i energii życiowej. To ważne, aby podtrzymywać różne rodzaje aktywności, realizację zainteresowań i pasji, działań na rzecz rodziny, i różnych grup społecznych. Podtrzymujemy przez to własną samoocenę i otrzymujemy wsparcie w sytuacjach trudnych z różnych źródeł. Wynik wskazuje również, że kontroluje Pan/Pani spożywanie tytoniu i alkoholu.`,
      yellow: `Ocena wskazuje na niewielkie obniżenie aktywności, która pobudza do działania oraz kontroli realizacji potrzeb, w tym konieczności palenia tytoniu lub/i spożywania alkoholu. Obniżenie napędu do działania i energii życiowej może wynikać zarówno z przyczyn obiektywnych, np. czasowe obniżenie nastroju spowodowanego trudnymi doświadczeniami rodzinnymi lub być wynikiem towarzyszących chorób. Jest ważne, aby podtrzymywać różne rodzaje aktywności, realizację zainteresowań i pasji, działań na rzecz rodziny, i różnych grup społecznych. Podtrzymujemy przez to własną samoocenę i otrzymujemy wsparcie w sytuacjach trudnych z różnych źródeł. Jeżeli nie dajemy sobie rady z poczuciem odizolowania i spadkiem aktywności życiowej, najlepiej skontaktować się ze specjalistą: lekarzem lub psychologiem.`,
      red: `Ocena wskazuje na poważne obniżenie aktywności, która pobudza do działania oraz kontroli realizacji potrzeb, w tym konieczności palenia tytoniu lub/i spożywania alkoholu. Obniżenie napędu do działania i energii życiowej może wynikać zarówno z przyczyn obiektywnych, np. czasowe obniżenie nastroju spowodowanego trudnymi doświadczeniami rodzinnymi lub być wynikiem towarzyszących chorób. Jest ważne, aby podtrzymywać różne rodzaje aktywności, realizację zainteresowań i pasji, działań na rzecz rodziny, i różnych grup społecznych. Podtrzymujemy przez to własną samoocenę i otrzymujemy wsparcie w sytuacjach trudnych z różnych źródeł. Jeżeli nie dajemy sobie rady z własnymi problemami, spadkiem aktywności życiowej, najlepiej skontaktować się ze specjalistą: lekarzem lub psychoterapeutą. Pomoc specjalistyczna może pomóc w zdiagnozowaniu przyczyn i określeniu działań wspierających.`
    }
  },
  {
    code: "B134",
    colors: {
      green: `Ocena wskazuje na dobrą jakość snu. Nie występują u Pani/Pana problemy z zasypianiem, ciągłością lub długością snu. Warto podtrzymywać ten korzystny stan, gdyż sen jest czynnością fizjologiczną, na którą przeznaczamy ok. 1/3 życia. Warto nadal dbać o sprzyjające warunki do snu zarówno materialne (wygodny materac, dobrze przewietrzone pomieszczenie, nie korzystanie z telefonów i innych wyświetlaczy przed snem), jak i wyciszenie po doświadczeniach całego dnia.`,
      yellow: `Ocena wskazuje na występowanie problemów ze snem. Odczuwane przez Pana/Panią problemy mogą mieć różne przyczyny i konsekwencje. W grupie osób narażonych na zaburzenia jakości snu są np. osoby kobiety w okresie klimakterium, osoby pracujące na zmiany i w podeszłym wieku. Przyczyny zaburzeń snu mogą być związane z warunkami otoczenia (np. hałas, upały), złymi nawykami (np. nieregularne pory snu, ciężkostrawne posiłki przed planowanym snem, korzystanie z wyświetlaczy lub monitorów w godzinach wieczornych, niewygodny materac). Przyczyny zaburzeń snu mogą wiązać się również z chorobami (np. depresja, stany lękowe), przeżywaniem trudnych doświadczeń lub towarzyszącemu bólowi w różnych obszarach (np. bóle kręgosłupa, migreny, bóle pourazowe). Konsekwencjami niedoboru snu mogą być: obniżenie funkcji poznawczych i zaburzenia uwagi, trudność zapamiętywania i uczenia się nowych rzeczy; obniżenie nastroju, stany lękowe; słabsza wydolność fizyczna, nadmierna męczliwość; większa podatność na infekcje i zakażenia; zwiększenie ryzyka chorób przewlekłych takich jak cukrzyca, nadciśnienie tętnicze i inne choroby kardiologiczne. Jeżeli nie dajemy sobie rady z zaburzeniami snu,  najlepiej skontaktować się ze specjalistą: lekarzem lub psychologiem.`,
      red: `Ocena wskazuje na występowanie poważnych problemów ze snem. Odczuwane przez Pana/Panią problemy mogą mieć różne przyczyny i konsekwencje. Przyczyny zaburzeń snu mogą być związane z warunkami otoczenia (np. hałas, upały), złymi nawykami (np. nieregularne pory snu, ciężkostrawne posiłki przed planowanym snem, korzystanie z wyświetlaczy lub monitorów w godzinach wieczornych, niewygodny materac). Przyczyny zaburzeń snu mogą wiązać się również z chorobami (np. depresja, stany lękowe), przeżywaniem trudnych doświadczeń lub towarzyszącemu bólowi w różnych obszarach (np. bóle kręgosłupa, migreny, bóle pourazowe). Konsekwencjami niedoboru snu mogą być: obniżenie funkcji poznawczych i zaburzenia uwagi, trudność zapamiętywania i uczenia się nowych rzeczy; obniżenie nastroju, stany lękowe; słabsza wydolność fizyczna, nadmierna męczliwość; większa podatność na infekcje i zakażenia; zwiększenie ryzyka chorób przewlekłych takich jak cukrzyca, nadciśnienie tętnicze i inne choroby kardiologiczne. Jeżeli zaburzenia snu wpływają na nasze samopoczucie, funkcjonowanie i zdrowie konieczne jest skontaktowanie się ze specjalistą: lekarzem psychiatrą lub psychologiem.`
    }
  },
  {
    code: "B140",
    colors: {
      green: `Ocena wskazuje na dobrą jakość uwagi. Nie występują u Pani/Pana problemy koncentracją, i podzielnością uwagi. Ten dobry stan może być wynikiem dobrego ogólnego stanu zdrowia i codziennego treningu funkcji poznawczych dzięki aktywności zawodowej oraz wykonywaniu licznych czynności w życiu prywatnym. Warto nadal dbać o aktywność percepcyjną i manualną angażujących uwagę w różnych zadaniach (np. prowadzenie samochodu, majsterkowanie, gry komputerowe). `,
      yellow: `Ocena wskazuje na występowanie niewielkich problemów z uwagą, które mogą mieć formę zaburzeń koncentracji lub podzielności uwagi. Odczuwane przez Pana/Panią problemy mogą mieć różne przyczyny i konsekwencje. Sprawność uwagi jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznością jej angażowania. Zaburzenia uwagi mogą pojawiać się w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako działanie uboczne oddziaływania środków farmakologicznych. Naturalne formy ćwiczenia uwagi wymagają dobrego ogólnego stanu zdrowia i codziennego treningu funkcji poznawczych dzięki aktywności zawodowej oraz wykonywaniu licznych czynności w życiu prywatnym. Warto dbać o aktywność percepcyjną i manualną angażującą uwagę w różnych formach (np. prowadzenie samochodu, majsterkowanie, gry komputerowe) o ile stan sprawności uwagi pozwala na bezpieczne wykonywanie tych zadań. W przypadku trudności w codziennym życiu i w pracy wynikających z zaburzeń uwagi należy skontaktować się z lekarzem neurologiem lub psychologiem. `,
      red: `Ocena wskazuje na występowanie poważnych problemów z uwagą, które mogą mieć formę zaburzeń koncentracji lub podzielności uwagi. Odczuwane przez Pana/Panią problemy mogą mieć różne przyczyny i konsekwencje. Sprawność uwagi jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznością jej angażowania. Zaburzenia uwagi mogą pojawiać się w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako działanie uboczne oddziaływania środków farmakologicznych. Jeżeli jest to możliwe, warto dbać o aktywność percepcyjną i manualną angażującą uwagę w różnych formach (np. majsterkowanie, gry logiczne, krzyżówki) o ile stan sprawności uwagi pozwala na bezpieczne wykonywanie tych zadań. W przypadku trudności w codziennym życiu i w pracy wynikających z zaburzeń uwagi należy skontaktować się z lekarzem neurologiem lub psychologiem. `
    }
  },
  {
    code: "B144",
    colors: {
      green: `Ocena wskazuje na brak problemów z pamięcią. Nie występują u Pani/Pana problemy z pamięcią długotrwałą i krótkotrwałą. Ta sprawność może być wynikiem dobrego ogólnego stanu zdrowia i codziennego treningu funkcji poznawczych dzięki aktywności zawodowej oraz realizacji zadań w życiu prywatnym wykorzystujących funkcję pamięci. Zaburzenia pamięci pojawiają się w tzw. łagodnych zaburzeniach funkcji poznawczych i najczęściej wynikają z fizjologicznego procesu starzenia się. `,
      yellow: `Ocena wskazuje na występowanie niewielkich problemów z pamięcią. Mogą one przybierać formę sporadycznych sytuacji zapominania imion, dat, zdarzeń ale również mogą mieć charakter funkcjonalny (zapomnienie gdzie położyłem/am okulary, klucze). Sprawność pamięci jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznym treningiem funkcji poznawczych w aktywności zawodowej oraz realizacji zadań w życiu prywatnym. Zaburzenia mogą pojawiać się również w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako działanie uboczne oddziaływania środków farmakologicznych. W przypadku trudności w codziennym funkcjonowaniu z powodu zaburzeń pamięci należy skontaktować się z lekarzem neurologiem lub psychologiem.	`,
      red: `Ocena wskazuje na występowanie dużych problemów z pamięcią. Mogą one przybierać formę sytuacji zapominania imion, dat, zdarzeń ale również mogą mieć charakter funkcjonalny (zapominanie gdzie położyłem/am okulary, klucze). Sprawność pamięci jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznym treningiem funkcji poznawczych w aktywności zawodowej oraz realizacji zadań w życiu prywatnym. Zaburzenia mogą pojawiać się również w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako działanie uboczne oddziaływania środków farmakologicznych. W przypadku trudności w codziennym funkcjonowaniu z powodu zaburzeń pamięci należy skontaktować się z lekarzem neurologiem lub psychologiem.`
    }
  },
  {
    code: "B152",
    colors: {
      green: `Ocena wskazuje na brak problemów z odczuwaniem i regulacją emocji. Nie występowały u Pani/Pana uczucia skrajnej złości i gniewu, lęki lub chwiejność emocjonalna. To wskazuje na brak skrajnych stanów emocjonalnych, które mogą wynikać z doświadczania silnego stresu. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym. `,
      yellow: `Ocena wskazuje na występowanie problemów z odczuwaniem i regulacją emocji. Mogą się przejawiać w uczuciach skrajnej złości i gniewu, lęku lub chwiejności emocjonalnej. Ten stan wskazuje na możliwość występowania skrajnych stanów emocjonalnych, które najczęściej wynikają z doświadczania silnego stresu. Można przypuszczać, że osoba mająca taki wynik może mieć trudności w sprawnym funkcjonowaniu w życiu zawodowym i prywatnym, szczególnie gdy występują dodatkowe sytuacje stresujące. W przypadku trudności w codziennym funkcjonowaniu z powodu zaburzeń emocji należy skontaktować się z lekarzem lub psychologiem.`,
      red: `Ocena wskazuje na występowanie istotnych problemów z odczuwaniem i regulacją emocji. Mogą się przejawiać w uczuciach skrajnej złości lub gniewu, lęku lub chwiejności emocjonalnej. Ten stan wskazuje na występowanie skrajnych stanów emocjonalnych, które najczęściej wynikają z doświadczania silnego stresu lub zaburzeń chorobowych. Można przypuszczać, że osoba mająca taki wynik ma trudności w sprawnym funkcjonowaniu w życiu zawodowym i prywatnym, szczególnie gdy występują dodatkowe sytuacje stresujące. W przypadku trudności w codziennym funkcjonowaniu z powodu zaburzeń emocji konieczny jest kontakt z lekarzem lub psychologiem.`
    }
  },
  {
    code: "B240",
    colors: {
      green: `Ocena wskazuje, że badana osoba nie ma problemów z utrzymaniem równowagi ciała zależnym od funkcji przedsionka. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym. `,
      yellow: `Ocena wskazuje na niewielkiego/średniego stopnia upośledzenie funkcji przedsionka, co może wpływać na sprawne funkcjonowanie w życiu zawodowym i prywatnym. Przy zaburzeniach funkcji błędnika dochodzi do trudności w utrzymaniu równowagi, którym towarzyszą zawroty głowy i nudności. W celu diagnostyki zaburzeń należy skontaktować się z lekarzem specjalistą (laryngolog, audiolog, foniatra).	`,
      red: `Ocena ta wskazuje na znacznego stopnia upośledzenie funkcji przedsionka. Mogą występować znacznego stopnia zaburzenia równowagi, które uniemożliwiają sprawne funkcjonowanie  w życiu zawodowym i prywatnym.`
    }
  },
  {
    code: "B280",
    colors: {
      green: `Ocena wskazuje na brak lub niewielkie dolegliwości bólowe. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym. W przypadku wystąpienia dolegliwości bólowych skonsultuj się z lekarzem lub fizjoterapeutą w celach diagnostycznych.`,
      yellow: `Ocena wskazuje na występowanie dolegliwości bólowych, które mogą wpływać na sprawne funkcjonowanie w życiu zawodowym i prywatnym. Bóle pleców, głowy, brzucha czy w kończynach ciała mogą wskazywać na problemy zdrowotne. W celu diagnostyki dolegliwości bólowych skontaktuj się z lekarzem. Tryb życia, przestrzeganie zasad profilaktyki jak i leczenie zachowawcze mają znaczny wpływ na ograniczenie dolegliwości bólowych a w konsekwencji zahamowanie procesu chorobowego. Aktywny tryb życia jest podstawą do zachowania odpowiedniej masy ciała, siły mięśniowej a także utrzymania lub poprawy zakresów ruchu w stawach całego ciała. Dodatkowo w walce z dolegliwościami bólowymi istotna jest znajomość i stosowanie zasad ergonomii zarówno w pracy jak i podczas wykonywania codziennych czynności. W przypadku leczenia zachowawczego należy wziąć pod uwagę zarówno leczenie fizjoterapeutyczne, edukację, psychoterapię a także farmakoterapię. Istotna jest diagnostyka dolegliwości bólowych z rozpoznaniem przyczyn bólu.  Przed rozpoczęciem aktywności fizycznej skonsultuj się z lekarzem lub fizjoterapeutą w celu dobrania odpowiedniej aktywności do Twojego stanu zdrowia.`,
      red: `Ocena wskazuje na występowanie dużych lub bardzo dużych dolegliwości bólowych, które mogą ograniczać sprawne funkcjonowanie w życiu zawodowym i prywatnym. Bóle pleców, głowy, brzucha czy w kończynach ciała mogą wskazywać na poważne problemy zdrowotne. Istotna jest diagnostyka dolegliwości bólowych z rozpoznaniem przyczyn bólu. W celu diagnostyki dolegliwości bólowych skontaktuj się z lekarzem. Tryb życia, przestrzeganie zasad profilaktyki jak i leczenie zachowawcze mają znaczny wpływ na ograniczenie dolegliwości bólowych a w konsekwencji zahamowanie procesu chorobowego. Aktywny tryb życia jest podstawą do zachowania odpowiedniej masy ciała, siły mięśniowej a także utrzymania lub poprawy zakresów ruchu w stawach całego ciała. Dodatkowo w walce z dolegliwościami bólowymi istotna jest znajomość i stosowanie zasad ergonomii zarówno w pracy jak i podczas wykonywania codziennych czynności. W przypadku leczenia zachowawczego należy wziąć pod uwagę zarówno leczenie fizjoterapeutyczne, edukację, psychoterapię a także farmakoterapię. `
    }
  },
  {
    code: "B420",
    colors: {
      green: `Ocena wskazuje na prawidłowe ciśnienie tętnicze krwi lub ciśnienie prawidłowo kontrolowane farmakologicznie. O prawidłowym ciśnieniu człowieka mówimy wtedy, gdy nie przekracza ono 120-129/80-84 mmHg. Szczególnie ważne jest, aby regularnie wykonywać pomiar ciśnienia krwi w domu. Tylko regularne mierzenie ciśnienia przez dłuższy okres czasu, da miarodajną informację o stanie naszego układu krążenia. Dla zachowania prawidłowego ciśnienia tętniczego krwi bardzo ważny jest zdrowy styl życia - zdrowe odżywianie, aktywność fizyczna, prawidłowa masa ciała, rzucenie palenia. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym`,
      yellow: `Ocena wskazuje na łagodnie lub umiarkowanie podwyższone ciśnienie tętnicze krwi tj. poniżej 180/110  mm Hg. Konieczna jest konsultacja z lekarzem. Szybko postawione rozpoznanie i wdrożenie odpowiedniego leczenia chroni przed rozwojem choroby i powikłaniami. Jeżeli rozpoznano u Pana/Pani nadciśnienie, należy stosować się ściśle do zaleceń lekarzy, przyjmować leki zgodnie ze schematem oraz stawiać się na zaplanowane wizyty i badania. Należy zmodyfikować swoją dietę i utrzymywać odpowiedni poziom BMI <25kg/m2. Jeśli występuje nadwaga/otyłość należy podejmować działania zmierzające ku zmniejszeniu masy ciała. Należy ograniczyć spożycie sodu (soli) do 5-6g/doba, ograniczyć spożycie alkoholu oraz rzucić palenie. Należy wprowadź odpowiednią do swoich możliwości i stanu zdrowia aktywność fizyczną oraz postarać się nauczyć jak radzić sobie ze stresem i wypracować metody na obniżenie napięcia. Należy kontrolować ciśnienie przynajmniej raz dziennie i/lub przy pogorszeniu samopoczucia i zapisywać wyniki w dzienniczku kontroli nadciśnienia.`,
      red: `Ocena wskazuje na dużego stopnia nadciśnienie tętnicze (powyżej 180/110 mm Hg). Konieczna jest pilna konsultacja z lekarzem. Szybko postawione rozpoznanie i wdrożenie odpowiedniego leczenia chroni przed rozwojem choroby i powikłaniami, a jeżeli wysokie ciśnienie tętnicze krwi występuje pomimo przyjmowania leków na nadciśnienie wskazuje to na potrzebę zmiany dawki lub preparatu. Należy stosować się ściśle do zaleceń lekarzy, przyjmować leki zgodnie ze schematem oraz stawiać się na zaplanowane wizyty i badania. Należy zmodyfikować swoją dietę i utrzymywać odpowiedni poziom BMI <25kg/m2. Jeśli występuje nadwaga/otyłość należy podejmować  działania zmierzające ku zmniejszeniu masy ciała. Należy ograniczyć spożycie sodu (soli) do 5-6g/doba, ograniczyć spożycie alkoholu oraz rzucić palenie. Należy wprowadź odpowiednią do swoich możliwości i stanu zdrowia aktywność fizyczną oraz postarać się nauczyć jak radzić sobie ze stresem i wypracować metody na obniżenie napięcia. Należy kontrolować ciśnienie przynajmniej kilka razy dziennie i/lub przy pogorszeniu samopoczucia oraz zapisywać wartości w dzienniczku kontroli nadciśnienia. `
    }
  },
  {
    code: "B455",
    colors: {
      green: `Ocena wskazuje na brak problemów z tolerancją wysiłku. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym. `,
      yellow: `Ocena wskazuje na obniżenie funkcji związanych z tolerancją wysiłku, która może być efektem zbyt małej aktywności fizycznej oraz zwiększonego czasu spędzonego w pozycji siedzącej. U osób dorosłych, nadmiar czasu spędzonego w pozycji siedzącej i brak aktywności fizycznej mogą być związane z niekorzystnymi skutkami zdrowotnymi t.j. śmiertelnością między innymi z powodu chorób układu krążenia, nowotworów oraz występowanie cukrzycy typu 2. Aktywność fizyczna zmniejsza ryzyko występowania wymienionych chorób i śmiertelności. Dodatkowo aktywność fizyczna może wpływać pozytywnie na poprawę zdrowia psychicznego (zmniejszone objawy lęku i depresji, poprawę snu i funkcji poznawczych oraz mogą ulec poprawie wskaźniki otyłości. Zaleca się, aby wszystkie osoby dorosłe podejmowały regularna aktywność fizyczną. Według Światowej Organizacji Zdrowia (WHO) dorośli w wieku 18-65 lat w celu uzyskania korzyści zdrowotnych powinni w tygodniu podejmować co najmniej 150-300 minut aerobowej aktywności fizycznej o umiarkowanej intensywności lub co najmniej 75-150 minut aerobowej aktywności fizycznej o dużej intensywności lub równoważne połączenie aktywności o umiarkowanej i dużej intensywności. Dodatkowo w celu zapewnienia dodatkowych korzyści zdrowotnych dorośli przez co najmniej 2 dni w tygodniu powinni wykonywać ćwiczenia wzmacniające główne grupy mięśni. Ważne! podejmowanie jakiejkolwiek aktywności fizycznej jest lepsze niż jej brak. Każda aktywność fizyczna będzie korzystna dla zdrowia osób dorosłych, nawet jeśli nie spełniają zaleceń. Aktywność fizyczną należy rozpocząć od niewielkich intensywności a z czasem stopniowo zwiększać intensywność, częstość i czas jej trwania. W przypadku występowania podczas ćwiczeń dolegliwości należy skonsultować się z lekarzem i/lub fizjoterapeutą. `,
      red: `Ocena wskazuje na znaczne obniżenie funkcji związanych z tolerancją wysiłku, która może być efektem zbyt małej aktywności fizycznej oraz zwiększonego czasu spędzonego w pozycji siedzącej. U osób dorosłych, nadmiar czasu spędzonego w pozycji siedzącej i brak aktywności fizycznej mogą być związane z niekorzystnymi skutkami zdrowotnymi t.j. śmiertelnością między innymi z powodu chorób układu krążenia, chorób układu krążenia, nowotworów oraz występowanie cukrzycy typu 2. Aktywność fizyczna zmniejsza ryzyko występowania wymienionych chorób i śmiertelności. Dodatkowo aktywność fizyczna może wpływać pozytywnie na poprawę zdrowia psychicznego (zmniejszone objawy lęku i depresji, poprawę snu i funkcji poznawczych oraz mogą ulec poprawie wskaźniki otyłości). Zaleca się, aby wszystkie osoby dorosłe podejmowały regularna aktywność fizyczną. Według Światowej Organizacji Zdrowia (WHO) dorośli w wieku 18-65 lat w celu uzyskania korzyści zdrowotnych powinni w tygodniu podejmować co najmniej 150-300 minut aerobowej aktywności fizycznej o umiarkowanej intensywności lub co najmniej 75-150 minut aerobowej aktywności fizycznej o dużej intensywności lub równoważne połączenie aktywności o umiarkowanej i dużej intensywności. Dodatkowo w celu zapewnienia dodatkowych korzyści zdrowotnych dorośli przez co najmniej 2 dni w tygodniu powinni wykonywać ćwiczenia wzmacniające główne grupy mięśni. Ważne! podejmowanie jakiejkolwiek aktywności fizycznej jest lepsze niż jej brak. Każda aktywność fizyczna będzie korzystna dla zdrowia osób dorosłych, nawet jeśli nie spełniają zaleceń. Aktywność fizyczną należy rozpocząć od niewielkich intensywności a z czasem stopniowo zwiększać intensywność, częstość i czas jej trwania. W przypadku występowania podczas ćwiczeń dolegliwości należy skonsultować się z lekarzem i/lub fizjoterapeutą. `
    }
  },
  {
    code: "B710",
    colors: {
      green: `Ocena wskazuje na brak problemów z ruchomością stawów.  Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym. `,
      yellow: `Ocena wskazuje na obniżenie funkcji ruchomości stawów, która może być efektem zbyt małej aktywności fizycznej oraz zbyt długiego czasu spędzanego w pozycji siedzącej. Brak ruchu osłabia mięśnie oraz może powodować ograniczenie możliwości wykonywania ruchów w pełnym zakresie. Zaleca się, aby wszystkie osoby dorosłe podejmowały regularna aktywność fizyczną. Według Światowej Organizacji Zdrowia (WHO) dorośli w wieku 18-65 lat w celu uzyskania korzyści zdrowotnych powinni w tygodniu podejmować co najmniej 150-300 minut aerobowej aktywności fizycznej o umiarkowanej intensywności lub co najmniej 75-150 minut aerobowej aktywności fizycznej o dużej intensywności lub równoważne połączenie aktywności o umiarkowanej i dużej intensywności. Dodatkowo w celu zapewnienia dodatkowych korzyści zdrowotnych dorośli przez co najmniej 2 dni w tygodniu powinni wykonywać ćwiczenia wzmacniające główne grupy mięśni. Ważne! podejmowanie jakiejkolwiek aktywności fizycznej jest lepsze niż jej brak. Każda aktywność fizyczna będzie korzystna dla zdrowia osób dorosłych, nawet jeśli nie spełniają zaleceń. Aktywność fizyczną należy rozpocząć od niewielkich intensywności a z czasem stopniowo zwiększać intensywność, częstość i czas jej trwania. W przypadku występowania podczas ćwiczeń dolegliwości należy skonsultować się z lekarzem i/lub fizjoterapeutą. `,
      red: `Ocena wskazuje na znaczne obniżenie funkcji ruchomości stawów, która może być efektem zbyt małej aktywności fizycznej oraz zwiększonego czasu spędzonego w pozycji siedzącej. U osób dorosłych, nadmiar czasu spędzonego w pozycji siedzącej i brak aktywności fizycznej mogą być związane z niekorzystnymi skutkami zdrowotnymi t.j. śmiertelnością między innymi z powodu chorób układu krążenia, chorób układu krążenia, nowotworów oraz występowanie cukrzycy typu 2. Aktywność fizyczna zmniejsza ryzyko występowania wymienionych chorób i śmiertelności. Dodatkowo aktywność fizyczna może wpływać pozytywnie na poprawę zdrowia psychicznego (zmniejszone objawy lęku i depresji), poprawę snu i funkcji poznawczych oraz mogą ulec poprawie wskaźniki otyłości. Zaleca się, aby wszystkie osoby dorosłe podejmowały regularna aktywność fizyczną. Według Światowej Organizacji Zdrowia (WHO) dorośli w wieku 18-65 lat w celu uzyskania korzyści zdrowotnych powinni w tygodniu podejmować co najmniej 150-300 minut aerobowej aktywności fizycznej o umiarkowanej intensywności lub co najmniej 75-150 minut aerobowej aktywności fizycznej o dużej intensywności lub równoważne połączenie aktywności o umiarkowanej i dużej intensywności. Dodatkowo w celu zapewnienia dodatkowych korzyści zdrowotnych dorośli przez co najmniej 2 dni w tygodniu powinni wykonywać ćwiczenia wzmacniające główne grupy mięśni. Ważne! podejmowanie jakiejkolwiek aktywności fizycznej jest lepsze niż jej brak. Każda aktywność fizyczna będzie korzystna dla zdrowia osób dorosłych, nawet jeśli nie spełniają zaleceń. Aktywność fizyczną należy rozpocząć od niewielkich intensywności a z czasem stopniowo zwiększać intensywność, częstość i czas jej trwania. W przypadku występowania podczas ćwiczeń dolegliwości należy skonsultować się z lekarzem i/lub fizjoterapeutą. `
    }
  },
  {
    code: "B730",
    colors: {
      green: `Ocena wskazuje na brak problemów z siłą mięśniową. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym.  `,
      yellow: `Ocena wskazuje na obniżenie siły mięśniowej, która może być efektem zbyt małej aktywności fizycznej oraz zwiększonego czasu spędzonego w pozycji siedzącej. U osób dorosłych, nadmiar czasu spędzonego w pozycji siedzącej i brak aktywności fizycznej mogą być związane z niekorzystnymi skutkami zdrowotnymi t.j. śmiertelnością między innymi z powodu chorób układu krążenia, chorób układu krążenia, nowotworów oraz występowanie cukrzycy typu 2.  Aktywność fizyczna zmniejsza ryzyko występowania wymienionych chorób i śmiertelności. Dodatkowo aktywność fizyczna może wpływać pozytywnie na poprawę zdrowia psychicznego (zmniejszone objawy lęku i depresji, poprawę snu i funkcji poznawczych oraz mogą ulec poprawie wskaźniki otyłości). Zaleca się, aby wszystkie osoby dorosłe podejmowały regularna aktywność fizyczną. Według Światowej Organizacji Zdrowia (WHO) dorośli w wieku 18-65 lat w celu uzyskania korzyści zdrowotnych powinni w tygodniu podejmować co najmniej 150-300 minut aerobowej aktywności fizycznej o umiarkowanej intensywności lub co najmniej 75-150 minut aerobowej aktywności fizycznej o dużej intensywności lub równoważne połączenie aktywności o umiarkowanej i dużej intensywności. Dodatkowo w celu zapewnienia dodatkowych korzyści zdrowotnych dorośli przez co najmniej 2 dni w tygodniu powinni wykonywać ćwiczenia wzmacniające główne grupy mięśni. Ważne! podejmowanie jakiejkolwiek aktywności fizycznej jest lepsze niż jej brak. Każda aktywność fizyczna będzie korzystna dla zdrowia osób dorosłych, nawet jeśli nie spełniają zaleceń. Aktywność fizyczną należy rozpocząć od niewielkich intensywności a z czasem stopniowo zwiększać intensywność, częstość i czas jej trwania. W przypadku występowania podczas ćwiczeń dolegliwości należy skonsultować się z lekarzem i/lub fizjoterapeutą. `,
      red: `Ocena wskazuje na znaczne obniżenie siły mięśniowej, która może być efektem zbyt małej aktywności fizycznej oraz zwiększonego czasu spędzonego w pozycji siedzącej. U osób dorosłych, nadmiar czasu spędzonego w pozycji siedzącej i brak aktywności fizycznej mogą być związane z niekorzystnymi skutkami zdrowotnymi t.j. śmiertelnością między innymi z powodu chorób układu krążenia, chorób układu krążenia, nowotworów oraz występowanie cukrzycy typu 2.  Aktywność fizyczna zmniejsza ryzyko występowania wymienionych chorób i śmiertelności. Dodatkowo aktywność fizyczna może wpływać pozytywnie na poprawę zdrowia psychicznego (zmniejszone objawy lęku i depresji, poprawę snu i funkcji poznawczych oraz mogą ulec poprawie wskaźniki otyłości). Zaleca się, aby wszystkie osoby dorosłe podejmowały regularna aktywność fizyczną. Według Światowej Organizacji Zdrowia (WHO) dorośli w wieku 18-65 lat w celu uzyskania korzyści zdrowotnych powinni w tygodniu podejmować co najmniej 150-300 minut aerobowej aktywności fizycznej o umiarkowanej intensywności lub co najmniej 75-150 minut aerobowej aktywności fizycznej o dużej intensywności lub równoważne połączenie aktywności o umiarkowanej i dużej intensywności. Dodatkowo w celu zapewnienia dodatkowych korzyści zdrowotnych dorośli przez co najmniej 2 dni w tygodniu powinni wykonywać ćwiczenia wzmacniające główne grupy mięśni. Ważne! podejmowanie jakiejkolwiek aktywności fizycznej jest lepsze niż jej brak. Każda aktywność fizyczna będzie korzystna dla zdrowia osób dorosłych, nawet jeśli nie spełniają zaleceń. Aktywność fizyczną należy rozpocząć od niewielkich intensywności a z czasem stopniowo zwiększać intensywność, częstość i czas jej trwania. W przypadku występowania podczas ćwiczeń dolegliwości należy skonsultować się z lekarzem i/lub fizjoterapeutą.`
    }
  },
  {
    code: "D110",
    colors: {
      green: `Ocena wskazuje na brak problemów z funkcją widzenia lub odpowiednio skorygowaną wadę wzroku. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym. Osoby po 40 roku życia powinny kontrolować stan swojego wzroku co 1-2 lata, nawet gdy nie zauważają u siebie żadnych niepokojących objawów. Okulista może w wybranych przypadkach rekomendować częstsze wizyty.`,
      yellow: `Ocena wskazuje na występowanie niewielkiego/średniego stopnia upośledzenia funkcji widzenia. Oznacza to, że osoba badana może mieć problem z widzeniem przedmiotów podczas patrzenia w dal (np. odczytaniem numeru nadjeżdżającego autobusu, oglądaniem telewizji), widzeniem z bliska (w czasie korzystania z telefonu/czytania książki) lub widzeniem zmierzchowym. Mogą występować również problemy z rozróżnianiem barw. Przy występowaniu ww problemów należy skonsultować się z lekarzem okulistą. Starczowzroczność (inaczej presbiopia) jest efektem fizjologicznego starzenia się organizmu. Przedmioty, tekst czy obrazy, które znajdują się w zakresie wyciągniętej ręki lub nawet bliżej, przestają być widziane wyraźnie, co finalnie prowadzi do konieczności używania okularów do bliży.  W celu diagnostyki zaburzeń należy skontaktować się z lekarzem specjalistą (okulista).`,
      red: `Ocena wskazuje na ograniczenie funkcji widzenia w znacznym stopniu. Osoba mająca taki wynik jest w ograniczonym stopniu zdolna do sprawnego funkcjonowania w życiu zawodowym i prywatnym. W celu diagnostyki zaburzeń należy skontaktować się z lekarzem specjalistą (okulistą).`
    }
  },
  {
    code: "D115",
    colors: {
      green: `Ocena wskazuje, że badana osoba nie ma problemów ze słyszeniem . Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym.`,
      yellow: `Ocena wskazuje na niewielkiego/średniego stopnia upośledzenie funkcji słyszenia, co może wpływać na sprawne funkcjonowanie w życiu zawodowym i prywatnym. Osoba badana może mieć trudności ze zrozumieniem wypowiedzi innych osób oraz zweryfikowaniem kierunku, z którego pochodzi dźwięk, podgłaśnia odbiornik telewizyjny lub radiowy na tyle, że staje się to uciążliwe dla otoczenia, nie słyszy cichych dźwięków, takich jak np. szum liści, mogą występować szumy uszne. W celu diagnostyki zaburzeń należy skontaktować się z lekarzem lub specjalistą (laryngolog, audiolog, foniatra).	`,
      red: `Ocena ta wskazuje na upośledzenie funkcji słyszenia w znacznym stopniu. Występuje znacznego stopnia niedosłuch, który uniemożliwia sprawne funkcjonowanie  w życiu zawodowym i prywatnym. W celu diagnostyki zaburzeń należy skontaktować się z lekarzem lub specjalistą (laryngolog, audiolog, foniatra).`
    }
  },
  {
    code: "D160",
    colors: {
      green: `Ocena wskazuje na dobrą jakość skupiania uwagi. Nie występują u Pani/Pana problemy ze skupieniem uwagi na jednym rodzaju pracy przez dłuższy czas. Ten dobry stan może być wynikiem dobrego ogólnego stanu zdrowia i codziennego treningu funkcji poznawczych dzięki aktywności zawodowej oraz wykonywaniu licznych czynności w życiu prywatnym. Warto nadal dbać o aktywność percepcyjną i manualną angażujących uwagę w różnych zadaniach (np. prowadzenie samochodu, majsterkowanie, gry komputerowe). `,
      yellow: `Ocena wskazuje na występowanie niewielkich problemów ze skupieniem uwagi na jednym rodzaju pracy przez dłuższy czas. Odczuwane przez Pana/Panią problemy mogą mieć różne przyczyny i konsekwencje. Sprawność uwagi jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznością jej angażowania. Zaburzenia uwagi mogą pojawiać się w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako działanie uboczne oddziaływania środków farmakologicznych. Naturalne formy ćwiczenia uwagi wymagają dobrego ogólnego stanu zdrowia i codziennego treningu funkcji poznawczych dzięki aktywności zawodowej oraz wykonywaniu licznych czynności w życiu prywatnym. Warto dbać o aktywność percepcyjną i manualną angażującą uwagę w różnych formach (np. prowadzenie samochodu, majsterkowanie, gry komputerowe) o ile stan sprawności uwagi pozwala na bezpieczne wykonywanie tych zadań. W przypadku trudności w codziennym życiu i w pracy wynikających z zaburzeń uwagi należy skontaktować się z lekarzem neurologiem lub psychologiem. `,
      red: `Ocena wskazuje na występowanie poważnych problemów ze skupieniem uwagi na jednym rodzaju pracy przez dłuższy czas. Odczuwane przez Pana/Panią problemy mogą mieć różne przyczyny i konsekwencje. Sprawność uwagi jest uwarunkowana dobrym ogólnym stanem zdrowia i systematycznością jej angażowania. Zaburzenia uwagi mogą pojawiać się w wyniku niezdrowego trybu życia (np. nadmierne spożycie alkoholu, używanie środków psychoaktywnych) lub również jako działanie uboczne oddziaływania środków farmakologicznych. Jeżeli jest to możliwe, warto dbać o aktywność percepcyjną i manualną angażującą uwagę w różnych formach (np. majsterkowanie, gry logiczne, krzyżówki) o ile stan sprawności uwagi pozwala na bezpieczne wykonywanie tych zadań.  W przypadku trudności w codziennym życiu i w pracy wynikających z zaburzeń uwagi należy skontaktować się z lekarzem neurologiem lub psychologiem.`
    }
  },
  {
    code: "D175",
    colors: {
      green: `Ocena wskazuje na umiejętność sprawnego rozwiązywania problemów w życiu prywatnym i zawodowym. Życie prywatne, rodzinne i zawodowe wymaga od nas niemal nieustannego rozwiązywania problemów i podejmowania decyzji adekwatnych do sytuacji.  Znajdowanie odpowiedzi na pytania oraz identyfikowanie i analizowanie problemów pozwalają na ustalenie możliwych rozwiązań. Zależy to zarówno od sprawności naszych funkcji poznawczych ale również od braku uprzedzeń i nie kierowania się jedynie stereotypami.`,
      yellow: `Ocena wskazuje na występowanie niewielkich trudności w rozwiązywaniu problemów w życiu prywatnym lub zawodowym. Życie prywatne, rodzinne i zawodowe wymaga od nas niemal nieustannego rozwiązywania problemów i podejmowania decyzji adekwatnych do sytuacji.  Znajdowanie odpowiedzi na pytania oraz identyfikowanie i analizowanie problemów pozwalają na ustalanie możliwych rozwiązań i ocenianie potencjalnych efektów tych rozwiązań. Zależy to zarówno od sprawności naszych funkcji poznawczych ale również od braku uprzedzeń i nie kierowania się jedynie stereotypami. Ta sprawność ułatwia zdrowe i bezkonfliktowe funkcjonowanie we wszystkich środowiskach społecznych. W przypadku powtarzających się trudności z rozwiązywaniem problemów w życiu prywatnym i zawodowym, które wpływają na samopoczucie, zdrowie i relacje społeczne, należy skontaktować się z psychologiem lub lekarzem.`,
      red: `Ocena wskazuje na występowanie dużych trudności w rozwiązywaniu problemów w życiu prywatnym lub zawodowym. Życie prywatne, rodzinne i zawodowe wymaga od nas niemal nieustannego rozwiązywania problemów i podejmowania decyzji adekwatnych do sytuacji.  Znajdowanie odpowiedzi na pytania oraz identyfikowanie i analizowanie problemów pozwalają na ustalenie możliwych rozwiązań i ocenianie potencjalnych efektów tych rozwiązań. Zależy to zarówno od sprawności naszych funkcji poznawczych ale również od braku uprzedzeń i nie kierowania się jedynie stereotypami. Ta sprawność ułatwia zdrowe i bezkonfliktowe funkcjonowanie we wszystkich środowiskach społecznych. Przy tak dużych trudnościach z rozwiązywaniem problemów w życiu prywatnym i zawodowym, które wpływają na samopoczucie, zdrowie i relacje społeczne, należy skontaktować się z psychologiem lub lekarzem.`
    }
  },
  {
    code: "D177",
    colors: {
      green: `Ocena wskazuje na umiejętność sprawnego podejmowania decyzji w życiu zawodowym. Praca wymaga od nas niemal nieustannego podejmowania decyzji adekwatnych do sytuacji.  Znajdowanie odpowiedzi na pytania oraz identyfikowanie i analizowanie problemów pozwalają na ustalenie możliwych rozwiązań. Zależy to zarówno od sprawności naszych funkcji poznawczych ale również od braku uprzedzeń i nie kierowania się stereotypami. 	`,
      yellow: `Ocena wskazuje na na występowanie niewielkich trudności w podejmowaniu decyzji w życiu zawodowym. Praca wymaga od nas niemal nieustannego podejmowania decyzji adekwatnych do sytuacji.  Znajdowanie odpowiedzi na pytania oraz identyfikowanie i analizowanie problemów pozwalają na ustalenie możliwych rozwiązań. Zależy to zarówno od sprawności naszych funkcji poznawczych ale również od braku uprzedzeń i nie kierowania się stereotypami. Trudności te mogą mieć różne przyczyny i konsekwencje, np. mogą wynikać z powodu niepewności co do trafności decyzji lub niejasności sytuacji decyzyjnej.  Osoby z tymi trudnościami mogą w większym stopniu odczuwać obciążenie psychiczne związane z koniecznością podejmowania decyzji, pracy z klientem i wykonywania zadań pod presją czasu. W przypadku powtarzających się trudności z podejmowaniem decyzji w życiu zawodowym należy skontaktować się z psychologiem lub lekarzem.`,
      red: `Ocena wskazuje na na występowanie częstych trudności w podejmowaniu decyzji w życiu zawodowym. Praca wymaga od nas niemal nieustannego podejmowania decyzji adekwatnych do sytuacji.  Znajdowanie odpowiedzi na pytania oraz identyfikowanie i analizowanie problemów pozwalają na ustalenie możliwych rozwiązań. Zależy to zarówno od sprawności naszych funkcji poznawczych ale również od braku uprzedzeń i nie kierowania się stereotypami. Trudności te mogą mieć różne przyczyny i konsekwencje, np. mogą wynikać z powodu niepewności co do trafności decyzji lub niejasności sytuacji decyzyjnej.  Osoby z tymi trudnościami mogą w większym stopniu odczuwać obciążenie psychiczne związane z koniecznością podejmowania decyzji, pracy z klientem i wykonywania zadań pod presją czasu. W przypadku nieustających trudności z podejmowaniem decyzji w życiu zawodowym należy skontaktować się z psychologiem lub lekarzem.`
    }
  },
  {
    code: "D240",
    colors: {
      green: `Ocena wskazuje na brak problemów z radzeniem sobie ze stresem, które mogą wynikać z odczuwanej  presji czasu, konieczności podejmowania trudnych decyzji lub napiętych relacji ze współpracownikami lub klientami. To wskazuje na skuteczne strategie radzenia sobie ze stresem i sprzyjające warunki pracy. Ta sprawność wynika z dobrego stanu zdrowia pracownika i jest kluczowa dla ogólnego dobrostanu.`,
      yellow: `Ocena wskazuje na występowanie niewielkich trudności z radzeniem sobie ze stresem, które mogą wynikać z odczuwanej  presji czasu, konieczności podejmowania trudnych decyzji lub napiętych relacji ze współpracownikami lub klientami. Mogą się przejawiać w uczuciach napięcia i występowania skrajnych emocji. Może to wskazywać na nieskuteczne strategie radzenia sobie ze stresem,  niesprzyjające warunki pracy lub problemy ze zdrowiem. Warto zadbać o te aspekty, gdyż sprawność ta jest kluczowa dla ogólnego dobrostanu. W przypadku trudności w radzeniu sobie ze stresem należy skontaktować się z lekarzem lub psychologiem.`,
      red: `Ocena wskazuje na występowanie istotnych problemów z odczuwaniem i regulacją emocji. Mogą się przejawiać w uczuciach skrajnej złości lub gniewu, lęku lub chwiejności emocjonalnej. Ten stan wskazuje na występowanie skrajnych stanów emocjonalnych, które najczęściej wynikają z doświadczania silnego stresu lub zaburzeń chorobowych. Można przypuszczać, że osoba mająca taki wynik ma trudności w sprawnym funkcjonowaniu w życiu zawodowym i prywatnym, szczególnie gdy występują dodatkowe sytuacje stresujące. W przypadku trudności w codziennym funkcjonowaniu z powodu zaburzeń emocji konieczny jest kontakt z lekarzem lub psychologiem.`
    }
  },
  {
    code: "D360",
    colors: {
      green: `Ocena wskazuje na umiejętność sprawnego posługiwania się urządzeniami i technikami służącymi do porozumiewania się. Masowość zastosowania urządzeń elektronicznej w życiu zawodowym i prywatnym wymusza ciągłe podążanie za nowymi rozwiązaniami i przyswajanie nowych umiejętności. Może być to szczególnie trudne dla starszych pracowników ale Pani/Pana wynik wskazuje na wystarczające umiejętności do funkcjonowania w cyberspołeczeństwie. W przypadku trudności przy wprowadzaniu nowych rozwiązań technologicznych warto zwrócić się o wsparcie do pracodawcy lub współpracowników.`,
      yellow: `Ocena wskazuje na występowanie niewielkich trudności w posługiwaniu się urządzeniami i technikami służącymi do porozumiewania się. Masowość zastosowania urządzeń elektronicznej w życiu zawodowym i prywatnym wymusza ciągłe podążanie za nowymi rozwiązaniami i przyswajanie nowych umiejętności. Może być to szczególnie trudne dla starszych pracowników ale Pani/Pana wynik wskazuje na konieczność podniesienia umiejętności korzystania z tych urządzeń. W przypadku trudności przy wprowadzaniu nowych rozwiązań technologicznych warto zwrócić się o wsparcie do pracodawcy lub współpracowników.`,
      red: `Ocena wskazuje na występowanie trudności w posługiwaniu się urządzeniami i technikami służącymi do porozumiewania się. Masowość zastosowania urządzeń elektronicznej w życiu zawodowym i prywatnym wymusza ciągłe podążanie za nowymi rozwiązaniami i przyswajanie nowych umiejętności. Może być to szczególnie trudne dla starszych pracowników ale Pani/Pana wynik wskazuje na konieczność podniesienia umiejętności korzystania z tych urządzeń. W przypadku trudności przy wprowadzaniu nowych rozwiązań technologicznych warto zwrócić się o wsparcie do pracodawcy lub współpracowników.`
    }
  },
  {
    code: "D415",
    colors: {
      green: `Ocena wskazuje na brak problemów z przyjęciem i utrzymaniem różnych pozycji ciała przez dłuższy czas (np. stojącej, siedzącej, klęcznej i kucznej). Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym`,
      yellow: `Ocena wskazuje na problemy z utrzymaniem wybranych pozycji ciała przez dłuższy okres czasu (np. stojącej, siedzącej, klęcznej i kucznej).  Można przypuszczać, że osoba mająca taki wynik  może mieć trudności w sprawnym funkcjonowaniu w życiu zawodowym i prywatnym. W celu diagnostyki zaburzeń należy skontaktować się z lekarzem specjalistą lub fizjoterapeutą. Wraz z wiekiem może pogarszać się stabilność postawy a także obserwowane jest szybsze odczuwanie zmęczenia podczas wysiłku o charakterze statycznym. Wpływa to na możliwość długotrwałego i stabilnego utrzymywania pozycji ciała (np. stanie). Organizacja pracy powinna uwzględniać wykonywanie czynności różnorodnych wymagających zmiany pozycji ciała.`,
      red: `Ocena wskazuje na bardzo duże problemy bądź brak możliwości utrzymania wybranych pozycji ciała przez dłuższy okres czasu (np. stojącej, siedzącej, klęcznej i kucznej). Taka ocena może wskazywać na poważne problemy zdrowotne. W celu diagnostyki zaburzeń należy skontaktować się z lekarzem specjalistą lub fizjoterapeutą.  Wraz z wiekiem może pogarszać się stabilność postawy a także obserwowane jest szybsze odczuwanie zmęczenia podczas wysiłku o charakterze statycznym. Wpływa to na możliwość długotrwałego i stabilnego utrzymywania pozycji ciała (np. stanie). Organizacja pracy powinna uwzględniać wykonywanie czynności różnorodnych wymagających zmiany pozycji ciała.`
    }
  },
  {
    code: "D430",
    colors: {
      green: `Ocena wskazuje na brak problemów w zakresie przenoszenia i podnoszenia przedmiotów. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym. Warto jednak pamiętać, że podczas wykonywania ręcznych prac transportowych należy stosować się do kilku głównych zasad:
        <ul>
            <li>należy stosować sprzęt pomocniczy w celu zmniejszenia obciążenia układu mięśniowo-szkieletowego, a w szczególności kręgosłupa</li>
            <li>należy unikać dużych skrętów i niepotrzebnego pochylania tułowia</li>
            <li>należy dążyć do tego, aby pozycja ciała była możliwie najbardziej zbliżona do naturalnej</li>
            <li>najkorzystniejszą wysokością, z której podnoszone są przedmioty, jest wysokość bioder pracownika, wysokość ta powinna być dopasowana indywidualnie</li>
            <li>przedmioty przenoszone powinny mieć uchwyty</li>
            <li>przedmioty o dużych rozmiarach i dużej masie powinny być przenoszone zespołowo z zachowaniem wszystkich ww. środków bezpieczeństwa i zaleceń dotyczących sposobów podnoszenia i przenoszenia przedmiotów</li>
        </ul>
      `,
      yellow: `Ocena wskazuje na występowanie problemów w zakresie przenoszenia i podnoszenia przedmiotów. Pamiętaj, że podczas wykonywania ręcznych prac transportowych należy stosować się do kilku głównych zasad:
        <ul>
            <li>należy stosować sprzęt pomocniczy w celu zmniejszenia obciążenia układu mięśniowo-szkieletowego, a w szczególności kręgosłupa</li>
            <li>należy unikać dużych skrętów i niepotrzebnego pochylania tułowia</li>
            <li>należy dążyć do tego, aby pozycja ciała była możliwie najbardziej zbliżona do naturalnej,</li>
            <li>najkorzystniejszą wysokością, z której podnoszone są przedmioty, jest wysokość bioder pracownika, wysokość ta powinna być dopasowana indywidualnie</li>
            <li>przedmioty przenoszone powinny mieć uchwyty</li>
            <li>przedmioty o dużych rozmiarach i dużej masie powinny być przenoszone zespołowo z zachowaniem wszystkich ww. środków bezpieczeństwa i zaleceń dotyczących sposobów podnoszenia i przenoszenia przedmiotów</li>
        </ul>
        Więcej informacji na temat doboru i zastosowania urządzeń wspomagających przenoszenie ładunków znajdziesz w: Tokarski T.2016 Poradnik.`,
      red: `Ocena wskazuje na znaczne ograniczenie lub brak możliwości podnoszenia i przenoszenia przedmiotów.  Zaleca się stosowanie urządzeń wspomagających podnoszenie i przenoszenie ładunków lub zastąpienie ręcznych prac transportowych odpowiednio dobranymi urządzeniami do transportu bliskiego.`
    }
  },
  {
    code: "D440",
    colors: {
      green: `Ocena wskazuje na brak problemów z precyzyjnym używaniem ręki. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym.`,
      yellow: `Ocena wskazuje na występujące problemy z precyzyjnym używaniem jednej lub obu rąk. U niektórych osób może zmniejszać się zdolność precyzyjnego używania ręki, co może wynikać z dolegliwości układu mięśniowo-szkieletowego jak i z pogorszenie funkcji narządu wzroku. W przypadku wykonywania czynności pracy wymagających precyzyjnego używania ręki należy zwrócić uwagę na lepszą stabilność postawy, wysokość płaszczyzny roboczej, która powinna być wyższa niż podczas pozostałych prac również ze względu na ewentualną szczegółową kontrolę wzrokową. Pomocna może być także możliwość zmniejszenia tempa pracy. Jeśli przyczyny występowania zaburzeń precyzyjnego używania rąk nie są znane, w celu diagnostyki problemu należy skontaktować się z lekarzem`,
      red: `Ocena wskazuje na występujące bardzo duże problemy z precyzyjnym używaniem jednej lub obu rąk. U niektórych osób może zmniejszać się zdolność precyzyjnego używania ręki, co może wynikać z dolegliwości układu mięśniowo-szkieletowego jak i z pogorszenie funkcji narządu wzroku. W przypadku wykonywania czynności pracy wymagających precyzyjnego używania ręki należy zwrócić uwagę na lepszą stabilność postawy, wysokość płaszczyzny roboczej, która powinna być wyższa niż podczas pozostałych prac również ze względu na ewentualną szczegółową kontrolę wzrokową. Pomocna może być także możliwość zmniejszenia tempa pracy. Jeśli przyczyny występowania zaburzeń precyzyjnego używania rąk nie są znane, w celu diagnostyki problemu należy skontaktować się z lekarzem`
    }
  },
  {
    code: "D445",
    colors: {
      green: `Ocena wskazuje na brak problemów z używaniem obu rąk i ramion. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym`,
      yellow: `Ocena wskazuje na występujące problemy z używaniem jednej lub obu rąk i ramion. Można przypuszczać, że osoba mająca taki wynik  może mieć trudności w sprawnym używanią rąk i ramion, co może wynikać ze zmniejszonej siły mięśni oraz zakresu ruchomości stawów. W przypadku kiedy występują ograniczenia używania rąk i ramion istotnym jest, aby znaleźć sposoby zmniejszenia obciążenia układu ruchu. Rekomendowane w tym przypadku jest
        <ul>
          <li>ergonomiczne dostosowywanie stanowisk pracy do rodzaju i charakteru wykonywanych czynności</li>
          <li>odpowiednie dobranie narzędzi dodatkowych</li>
          <li>organizacja czasu i tempa pracy</li>
          <li>rotacja na stanowiskach pracy</li>
          <li>przerwy na odpoczynek</li>
        </ul>
      `,
      red: `Ocena wskazuje na występujące bardzo duże problemy z używaniem jednej lub obu rąk i ramion (TU WPISAC KTÓREJ PRAWEJ czy LEWEJ a może OBU). Można przypuszczać, że osoba mająca taki wynik może mieć  znaczne trudności w sprawnym używania rąk i ramion. W przypadku kiedy występują znaczne ograniczenia używania rąk i ramion istotnym jest, aby znaleźć sposoby zmniejszenia obciążenia układu ruchu. Rekomendowane w tym przypadku jest
        <ul>
          <li>ergonomiczne dostosowywanie stanowisk pracy do rodzaju i charakteru wykonywanych czynności</li>
          <li>odpowiednie dobranie narzędzi dodatkowych</li>
          <li>organizacja czasu i tempa pracy</li>
          <li>przerwy na odpoczynek</li>
        </ul>
      `
    }
  },
  {
    code: "D450",
    colors: {
      green: `Ocena wskazuje na brak problemów z chodzeniem. Można przypuszczać, że osoba mająca taki wynik jest zdolna do sprawnego i „zdrowego” funkcjonowania w życiu zawodowym i prywatnym`,
      yellow: `Ocena wskazuje na pogorszone możliwości w zakresie chodu. Zaburzenie wzorca chodu może wskazywać na problemy z przemieszczaniem się na nierównym terenie, na dłuższe odległości, ograniczenie możliwości przyjmowania pozycji wymuszonych, takich jak pozycja z pochylonym tułowiem, kuczna czy klęczna oraz pracy na wysokości wymagającej przemieszczania się na podwyższeniu (np. drabina, podest, rusztowanie). Rekomendowane jest stanowisko z możliwością swobodnej zmiany pozycji, w zależności od potrzeb. W przypadku wykonywania prac na wysokości, to należy rozważyć stosowanie urządzeń wspomagających (np. podnośniki koszowe, podesty ruchome). Jeśli przyczyny występowania zaburzeń chodu nie są znane, w celu diagnostyki problemu należy skontaktować się z lekarzem.`,
      red: `Ocena wskazuje na znacznie pogorszone możliwości w zakresie chodu.  Jeśli przyczyny występowania zaburzeń chodu nie są znane, w celu diagnostyki problemu należy skontaktować się z lekarzem.`
    }
  },
  {
    code: "D720",
    colors: {
      green: `Ocena wskazuje na umiejętność dobrego funkcjonowania w relacjach z ludźmi w życiu prywatnym i zawodowym. Życie prywatne, rodzinne i zawodowe wymaga od nas niemal nieustannego kontaktowania się z innymi. Niektórzy z nas lubią takie relacje i sami je inicjują (są ekstrawertykami) , a inni wolą przebywać w samotności lub z pojedynczymi znanymi sobie osobami (są introwertykami). Bez względu na te indywidualne różnice Pani/Pana samoocena wskazuje na braku uprzedzeń i dobre relacje ze współpracownikami i mniej znanymi osobami.`,
      yellow: `Ocena wskazuje na występowanie niewielkich trudności w funkcjonowaniu w relacjach z ludźmi w życiu prywatnym i zawodowym. Życie prywatne, rodzinne i zawodowe wymaga od nas niemal nieustannego kontaktowania się z innymi. Niektórzy z nas lubią takie relacje i sami je inicjują (są ekstrawertykami) , a inni wolą przebywać w samotności lub z pojedynczymi znanymi sobie osobami (są introwertykami). Bez względu na te indywidualne różnice Pani/Pana samoocena wskazuje na niewielkie trudności w  relacjach ze współpracownikami i mniej znanymi osobami. W przypadku powtarzających się trudności w kontaktach międzyludzkich, które wpływają na samopoczucie i relacje społeczne, należy skontaktować się z psychologiem lub bliską zaufaną osobą.`,
      red: `Ocena wskazuje na występowanie trudności w funkcjonowaniu w relacjach z ludźmi w życiu prywatnym i zawodowym. Życie prywatne, rodzinne i zawodowe wymaga od nas niemal nieustannego kontaktowania się z innymi. Niektórzy z nas lubią takie relacje i sami je inicjują (są ekstrawertykami) , a inni wolą przebywać w samotności lub z pojedynczymi znanymi sobie osobami (są introwertykami). Bez względu na te indywidualne różnice Pani/Pana samoocena wskazuje na niewielkie trudności w  relacjach ze współpracownikami i mniej znanymi osobami. W przypadku powtarzających się trudności w kontaktach międzyludzkich, które wpływają na samopoczucie i relacje społeczne, należy skontaktować się z psychologiem.`
    }
  },
]