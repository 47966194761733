import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useResultForEmployerFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(4)
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      marginTop: theme.spacing(2)
    }
  }),
)