import { useMutation } from '@apollo/client';
import { FormControl, FormControlLabel, FormLabel, Checkbox, FormHelperText, Typography} from '@material-ui/core';
import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import { SAVE_FACTOR_ANSWER } from '../../examComponents/examQuestionCard/ExamQuestionCard.mutation';
import { useButtonsControllerStyles } from '../buttonsController/ButtonsController.styles';

interface CheckboxControllerProps {
  name: string;
  question: string;
  options: Array<{ label: string, value: string }>
  examId: string;
}

const CheckboxController: FC<CheckboxControllerProps> = ({
  name,
  question,
  options,
  examId,
}) => {
  const { control } = useFormContext();
  const [saveAnswer,{loading}] = useMutation(SAVE_FACTOR_ANSWER)
  const classes = useButtonsControllerStyles()

  const sendAnswer = async (value: Array<string | null>, questionNumber: string) => {
    try {
      await saveAnswer({
        variables: {
          answers: value,
          questionNumber: Number(questionNumber),
          examId
        }
      })
    } catch(e) {
      console.error(e)
    }
  }

  return (
    <div>
      <Controller 
        name={name}
        control={control}
        defaultValue={[]}
        rules={{required: true}}
        render={(
          {
            field: {
              onChange,
              value
            },
            fieldState: {
              error,
              invalid
            }
          }
        ) => (
          <FormControl
            error={invalid}
          >
            <FormLabel>
              { question }
            </FormLabel>

            {error && (
              <Typography
                variant={"subtitle1"}
                className={classes.errorText}
              >
                Odpowiedź jest wymagana
              </Typography>
            )}

            {options.map(option => (
              <FormControlLabel
                key={option.label}
                label={ option.label }
                control={
                  <Checkbox 
                    checked={value.includes(option.value)} 
                    value={option.value}
                    disabled={loading}
                    onClick={() => {
                      const isValueSelected = value.find((item: string) => item === option.value)
                      const fieldValue = isValueSelected
                        ? value.filter(( item: string ) => item !== option.value)
                        : [...value, option.value]
                      onChange(fieldValue)
                      if(!["13", "18"].includes(option.value)) {
                        sendAnswer(!isValueSelected ? ["true"] : [null], option.value)
                      }
                    }}
                  />
                }
              />
            ))}

            <FormHelperText>
              {error?.message}
            </FormHelperText>
          </FormControl>
        )}
      />
    </div>
  )
}

export default CheckboxController