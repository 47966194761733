import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const useExamGroupsListActionsStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper:{
      display: "flex",
      flexDirection: "column",
      backgroundColor: theme.palette.background.default,
    },
    button: {
      padding: theme.spacing(1,2),
    },
    icon: {
      ...theme.typography.h5,
      marginRight: theme.spacing(.5)
    }
  }),
)
