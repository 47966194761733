import * as yup from 'yup'
import { requiredErrorMessage } from "../../formItems/errors/yupErrorMessages"

export const resultForEmployerValidationSchema = yup.object().shape({
  tenantId: yup.string().required(requiredErrorMessage),
  examGroups: yup.array().min(1, requiredErrorMessage),
  dateFrom: yup.date().typeError(requiredErrorMessage),
  dateTo: yup.date().typeError(requiredErrorMessage).min(
      yup.ref('dateFrom'),
      "Data do nie może być wcześniej niż od"
    )
})