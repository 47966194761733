import { gql } from "@apollo/client"

export const EXAM_GROUPS_LIST_QUERY = gql`
    query GetExamGroupsList(
        $first: Int,
        $offset: Int,
        $filter: ExamGroupFilter,
        $orderBy: [ExamGroupsOrderBy!]
    ) {
        connection: examGroups(first: $first, offset: $offset, filter: $filter, orderBy: $orderBy) {
            totalCount
            edges {
                node {
                    examGroupId
                    name
                    userscount
                    createdAt
                    tenant {
                        tenantId
                        name
                    }
                }
            }
        }
    }
`;
