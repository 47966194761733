import { UserRole } from "../../components/user/userRole/UserRole.types"
import {
  WorkplaceThreatsTypes
} from "../../components/testedPersons/testedPersonsWorkplaceThreatsSelect/TestedPersonsWorkplaceThreatsSelect.types";
import {
  Occupations
} from "../../components/testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.types";

export const USER_STORAGE_KEY = "user"

export type UserDataState = UserItemNode | null

export interface UserItemNode {
  userId: string,
  email: string,
  name: string,
  surname: string,
  fullName: string,
  userRole: UserRole,
  archived: boolean;
  lastActivity?: string;
  tenantsList?: TenantItem[];
  guardian: {
    name: string;
    surname: string;
  }
}

export interface AnonymousUser {
  fullName: string
}

export interface TenantItem {
  tenantId: string;
  name: string;
}

export interface ExaminedPersonUserItemNode extends UserItemNode {
  occupationType : {
    name: string;
    occupationTypeId: Occupations;
  },
  birthYear: string,
  age: string,
  ageCategoryId: string,
  genderId: string,
  motivation: number,
  seniorityId: string,
  educationId: string,
  weigh: string,
  height: string,
  bmi: string,
  workTypeId: string,
  workplaceThreats?: WorkplaceThreatsTypes[]
}

export interface UserQuery {
  data: {
    user: UserDataState
  }
}
