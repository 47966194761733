import {ColorLight} from "../examUtils/exam.types";
import {Color} from "@material-ui/lab/Alert/Alert";

export const getAlertSeverity = (color: ColorLight): Color => {
  switch (color) {
    case ColorLight.GREEN: return "success"
    case ColorLight.YELLOW: return "warning"
    case ColorLight.RED: return "error"
  }

}

export const getGeneralDescription = (color: ColorLight): string => {
  switch (color) {
    case ColorLight.GREEN:
      return "W oparciu o przeprowadzone badania oraz informacje o stanowisku pracy można stwierdzić, że organizacja pracy jest dobrze dostosowana do Pani/Pana możliwości psychofizycznych. Wszystkie Pani/Pana wyniki są w strefie zielonej, brawo! Pozwala to na wykonywanie powierzonych Pani/Panu obowiązków bez nadmiernego obciążenia pracą. Aby jak najdłużej utrzymać dobre zdrowie i sprawność można zwrócić uwagę na kilka aspektów wymienionych w rekomendacjach."
    case ColorLight.YELLOW:
      return "W oparciu o przeprowadzone badania oraz informacje o stanowisku pracy można stwierdzić, że organizacja pracy jest w znacznej mierze dostosowana do Pani/Pana możliwości psychofizycznych. Pani/Pana wyniki są w strefie zielonej lub żółtej. Wynik wskazuje możliwość wykonywania obowiązków bez nadmiernego obciążenia pracą, wskazane może być jednak wdrożenie niewielkich modyfikacji w zakresie organizacji pracy. Proszę zwrócić uwagę na rekomendacje, których uwzględnienie będzie sprzyjać zapewnieniu zdrowia i dobrej kondycji oraz jednocześnie dobrej efektywności pracy przez cały okres aktywności zawodowej."
    case ColorLight.RED:
      return "W oparciu o przeprowadzone badania oraz informacje o stanowisku pracy można stwierdzić, że organizacja pracy nie w pełni odpowiada Pani/Pana możliwościom psychofizycznym. Pani/Pana wyniki są w strefie zielonej, żółtej oraz czerwonej. Wskazane może być przeprowadzenie zmian na stanowisku pracy bądź przekwalifikowanie zawodowe. Proszę zwrócić uwagę na rekomendacje, których uwzględnienie będzie sprzyjać zapewnieniu zdrowia i dobrej kondycji oraz jednocześnie dobrej efektywności pracy przez cały okres aktywności zawodowej."
  }

}