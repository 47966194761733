import React, { useEffect, useState } from 'react'
import AdminPageCard from '../components/adminPageCard/AdminPageCard.component';
import ExamPageCard from '../components/examComponents/examPageCard/ExamPageCard.component';
import { Box, Typography } from '@material-ui/core';
import { useParams } from 'react-router';
import clientFetch from '../fetch';
import RecommendationCard from '../components/resultForEmployer/recommendationCard/RecommendationCard';
import CustomProgress from '../components/resultForEmployer/progress/CustomProgress';
import { activityOptions, privateOptions } from '../components/examComponents/examAdditionalSection/ExamAdditionalSection';
import AdditionalSourcesOfInformation from '../components/resultForEmployer/additionalSourcesOfInformation/AdditionalSourcesOfInformation';
import ProjectDataLogos from '../components/common/projectDataLogos/ProjectDataLogos.component';
import { format } from 'date-fns'

interface ExamResultsFormEmployerParams {
  from: string;
  to: string;
  groups: string;
  tenantId: string;
}

interface DataType {
  colors: {
    [key: string]: {
      red: number,
      yellow: number,
      green: number
    }
  },
  factors: {
    [key: string]: number
  }
  usersCount: number
}

const ExamResultsFormEmployer = () => {
  const {from, to, groups, tenantId} = useParams<ExamResultsFormEmployerParams>()
  const [data, setData] = useState<DataType>()
  const [loading, setLoading] = useState(true)
  const groupsIds = groups.split("groups=")[1]

  useEffect(() => {
    const getData = async () => {
      clientFetch(`/api/batch-result/${tenantId}/${from}/${to}/${groupsIds}`, {method: "GET"})
        .then(response => response.json())
        .then(values => {
          setData(values)
          setLoading(false)
        })
    }
    getData()
  },[])
  const answers = data && Object.entries(data?.colors)

  if(loading) return <p>loading</p>
  return (
    <AdminPageCard title={ "Karta wyników dla pracodawcy" }>
      <ExamPageCard fullName={"Karta wyników dla pracodawcy"}>
      <div id="pageIsReadyForPrinting"/>
      <Box mb={3}>
        <Typography align={"center"} variant={"h4"}>
          Ocena zdolności do pracy oraz perspektywy aktywności zawodowej pracowników w wieku przedemerytalnym i emerytalnym
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography>
          Termin przeprowadzenia samooceny przez pracowników: {from} - {to}
        </Typography>
        <Typography>
          Liczba pracowników którzy wypełnili ankietę: {data?.usersCount}
        </Typography>
        <Typography>
          Data pobrania wyników: {format(new Date(), "dd-MM-yyy")}
        </Typography>
      </Box>

      <Box>
        <Typography>
          Poniżej przedstawiono zbiorcze wyniki oceny zdolności do pracy w oparciu o kwestionariusz uwzględniający wybrane kategorie ICF (Międzynarodowej Klasyfikacji Funkcjonowania, Niepełnosprawności i Zdrowia). Uwzględniono różne aspekty zdrowia i funkcjonowania pracowników oraz ich potrzeby dotyczące kontynuacji ich aktywności zawodowej. Wynik dotyczący każdej ze zdiagnozowanych funkcji ilustruje kolorowy wykres. Kolor zielony oznacza, że pracownicy uzyskali wynik doskonały, kolor żółty – wynik dobry, kolor czerwony – wynik wskazujący na konieczność podjęcia działań wspierających. Przy wyniku poszczególnych funkcji i aktywności zaproponowano rekomendacje dla pracodawcy służące poprawie funkcjonowania zawodowego pracowników.
        </Typography>
      </Box>

      <Box my={4}>
        <Typography variant='h5'>
          Część 1. Samoocena pracowników
        </Typography>
      </Box>

      {answers?.map(item => (
        <RecommendationCard
          key={item[0]}
          code={item[0]}
          green={item[1]?.green}
          yellow={item[1]?.yellow}
          red={item[1]?.red}
        />
      ))}

      <Box my={4}>
        <Typography variant='h5'>
        Część 2. Perspektywy aktywności zawodowej
        </Typography>
      </Box>

      <CustomProgress
        title='Chęć kontynuacji pracy w aktualnym przedsiębiorstwie po przekroczeniu wieku emerytalnego (niebieski pasek przedstawia średnią dla całej grupy)'
        value={data?.factors["factor_19"] as number}
      />

      <CustomProgress
        title='Zadowolenie z działań pracodawcy na rzecz przedłużenia aktywności zawodowej pracowników po przekroczeniu wieku emerytalnego (niebieski pasek przedstawia średnią dla całej grupy)'
        value={data?.factors["factor_20"] as number}
      />

      <Typography variant='h5'>
        Czynniki związane z pracą:
      </Typography>

      {activityOptions.map(item => (
        <Typography>
          {item.value}. {item.label} - {data?.factors[`factor_${item.value}`] && Number(data?.factors[`factor_${item.value}`]) * 100 + " %"}
        </Typography>
      ))}

      <Typography variant='h5'>
        Czynniki związane z życiem prywatnym:
      </Typography>

      {privateOptions.map((item, index)=> (
        <Typography>
          {index + 1}. {item.label} - {data?.factors[`factor_${item.value}`] && Number(data?.factors[`factor_${item.value}`]) * 100 + " %"}
        </Typography>
      ))}

      <Box my={4}>
        <Typography variant='h5'>
          Część 3. Dodatkowe źródła informacji 
        </Typography>
      </Box>

      <AdditionalSourcesOfInformation/>

      </ExamPageCard>

      <ProjectDataLogos/>
    </AdminPageCard>
  )
}

export default ExamResultsFormEmployer