import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useCustomProgressStyles = makeStyles((theme: Theme) =>
  createStyles({
    bar: {
      display: "flex",
      maxWidth: "400px",
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden",
      margin: "16px 0",
      border: "1px solid #808080"
    },
    fill: {
      backgroundColor: "#1976d2",
      height: theme.spacing(2)
    }
  }),
)