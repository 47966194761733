import React, {FC, useState} from "react"
import {Box} from "@material-ui/core"
import {yupResolver} from "@hookform/resolvers/yup"
import Popup from "../../common/popup/Popup.component"
import {FormProvider, useForm} from "react-hook-form"
import TextFieldController from "../../formItems/textFieldController/TextFieldController.component"
import {ADD_EXAM_GROUP} from "./AddExamGroup.mutation"
import {useMutation} from "@apollo/client";
import generateUuid from "../../../utils/generateUuid"
import {addExamGroupValidationSchema} from "./AddExamGroup.validation"
import {useSelector} from "react-redux"
import {selectCurrentTenantId} from "../../../store/user/user.selectors";
import GlobalErrorSnackbar from "../../formItems/errors/globalErrorSnackbar/GlobalErrorSnackbar"
import TenantsSelect from "../../tenants/tenantsSelect/TenantsSelect.component";
import Grid from '@material-ui/core/Grid';
import {TenantSelectItem} from "../../tenants/tenantsSelect/TenantsSelect.types";

interface AddExamGroupPopupProps {
  open: boolean;
  refetch(): void;
  onClose(): void;
}

const AddExamGroupPopup: FC<AddExamGroupPopupProps> = ({open, refetch, onClose}) => {
  const tenantsId = useSelector(selectCurrentTenantId)
  const [addExamGroup, {loading}] = useMutation(ADD_EXAM_GROUP)
  const [createExamGroupError, setCreateExamGroupError] = useState<string>("")

  const form = useForm({
    mode: "all",
    resolver: yupResolver(addExamGroupValidationSchema),
    defaultValues: {
      name: "",
      tenantId: "",
    }
  })

  const handleClose = () => {
    onClose()
    form.reset()
  }

  const handleSubmit = form.handleSubmit(async (values) => {
    setCreateExamGroupError("")
    try {
      await addExamGroup({
        variables: {
          input: {
            examGroup: {
              examGroupId: generateUuid(),
              ...values
            }
          }
        }
      })

      await refetch()
      handleClose()
    } catch (e: any) {
      if (e.graphQLErrors && e.graphQLErrors[0]?.message === 'duplicate key value violates unique constraint "examGroups_name_key"') {
        form.setError("name", { message: "Grupa o podanej nazwie już istnieje" })
      } else {
        setCreateExamGroupError("Wystąpił nieznany błąd, prosimy spróbować ponownie")
      }
    }
  })

  return (
    <Popup
      open={open}
      onClose={handleClose}
      title={"Dodaj grupę do badań"}
      actionButtonText={"Dodaj"}
      actionButtonProps={{
        onClick: () => handleSubmit(),
        disabled: loading
      }}
      desktopWidth={"800px"}
    >
      <Box>
        <FormProvider {...form} >
          <form
            autoComplete="off"
            noValidate
          >
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextFieldController
                  name="name"
                  label={'Nazwa'}
                  maxCharacters={100}
                  isRequired
                  trimStart
                  mb
                  disabled={loading}
                />
              </Grid>

              <Grid item xs={6}>
                <TenantsSelect
                  name="tenantId"
                  label={'Podmiot'}
                  multiple={false}
                  filterBy={(tenant: TenantSelectItem) => {
                    return tenantsId.includes(tenant.tenantId)
                  }}
                  isRequired
                  mb
                  disabled={loading}
                />
              </Grid>
            </Grid>
          </form>
        </FormProvider>

        <GlobalErrorSnackbar
          isError={!!createExamGroupError}
          errorMessage={createExamGroupError}
        />
      </Box>
    </Popup>
  )
}

export default AddExamGroupPopup
