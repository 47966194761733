import { Divider, Grid, Typography } from '@material-ui/core';
import CheckboxController from '../../formItems/checkboxController/CheckboxController.component';
import ButtonGroupController from '../../formItems/buttonGroupController/ButtonGroupController';
import { FC } from 'react';
import { useWatch } from 'react-hook-form';
import TextFieldController from '../../formItems/textFieldController/TextFieldController.component';
import { useMutation } from '@apollo/client';
import { SAVE_FACTOR_ANSWER } from '../examQuestionCard/ExamQuestionCard.mutation';

interface ExamAdditionalSectionProps {
  examId: string
}

export const activityOptions = [
  {
    label: "Elastyczny czas pracy (ruchomy czas rozpoczęcia i zakończenia pracy)",
    value: "1"
  },
  {
    label: "Zmniejszenie tempa pracy",
    value: "2"
  },
  {
    label: "Praca tylko na zmiany dzienne",
    value: "3"
  },
  {
    label: "Możliwość pracy na część etatu",
    value: "4"
  },
  {
    label: "Zmiana zakresu obowiązków (np. ograniczenie czynności najbardziej obciążających fizycznie)",
    value: "5"
  },
  {
    label: "Wyższe wynagrodzenie",
    value: "6"
  },
  {
    label: "Możliwość rozwoju w pracy",
    value: "7"
  },
  {
    label: "Większe wsparcie od przełożonych",
    value: "8"
  },
  {
    label: "Większe wsparcie od współpracowników",
    value: "9"
  },
  {
    label: "Lepsza atmosfera w pracy ",
    value: "10"
  },
  {
    label: "Lepsze wykorzystanie mojej wiedzy i doświadczenia",
    value: "11"
  },
  {
    label: "Większe poczucie kontroli (np. dostęp do informacji o firmie i możliwość decydowania)",
    value: "12"
  },
  {
    label: "Inne",
    value: "13"
  }
]

export const privateOptions = [
  {
    label: "Wsparcie mojej decyzji przez najbliższą rodzinę",
    value: "14"
  },
  {
    label: "Brak konieczności opieki nad najbliższymi (np. rodzice, wnuki)",
    value: "15"
  },
  {
    label: "Łatwość dojazdu do pracy",
    value: "16"
  },
  {
    label: "Możliwość utrzymania równowagi między życiem prywatnym a zawodowym",
    value: "17"
  },
  {
    label: "Inne",
    value: "18"
  }
]

const ExamAdditionalSection: FC<ExamAdditionalSectionProps> = ({ examId }) => {
  const [additional_question_1Values, additional_question_2Values] = useWatch({
    name: ["additional_question_1", "additional_question_2"]
  })

  const [saveAnswer,{loading}] = useMutation(SAVE_FACTOR_ANSWER)

  const sendAnswer = async (value: Array<string | null>, questionNumber: string) => {
    try {
      await saveAnswer({
        variables: {
          answers: value,
          questionNumber: Number(questionNumber),
          examId
        }
      })
    } catch(e) {
      console.error(e)
    }
  }

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component={"p"}
            variant="h6"
          >
            1. Czynniki wspierające zatrudnienie w okresie przedemerytalnym i emerytalnym
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            component={"span"}
            variant="h5"
          >
            Co może wpływać na Pana/Pani decyzję o przedłużeniu aktywności zawodowej? 
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CheckboxController 
            name='additional_question_1'
            question='Czynniki związane z pracą:'
            options={activityOptions}
            examId={examId}
          />

          {additional_question_1Values?.includes("13") && (
            <TextFieldController
              name='13'
              id="13"
              requiredRule
              label='Inne'
              isRequired
              disabled={loading}
              inputProps={{
                onBlur: (e) => {
                  const value = e.target.value
                  sendAnswer([value], '13')
                }
              }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <CheckboxController 
            name='additional_question_2'
            question='Czynniki związane z życiem prywatnym'
            options={privateOptions}
            examId={examId}
          />

          {additional_question_2Values?.includes("18") && (
            <TextFieldController 
              name='18'
              id="18"
              label='Inne'
              requiredRule
              isRequired
              disabled={loading}
              inputProps={{
                onBlur: (e) => {
                  const value = e.target.value
                  sendAnswer([value], '18')
                }
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component={"p"}
            variant="h6"
          >
            2. Oceń na skali 10-cio stopniowej chęć kontynuacji pracy w aktualnym przedsiębiorstwie po przekroczeniu wieku emerytalnego
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            1 oznacza minimalne chęci
          </Typography>
          <Typography>
            10 oznacza maksymalne chęci
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ButtonGroupController
            name='19'
            examId={examId}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            component={"p"}
            variant="h6"
          >
            3. Oceń na skali 10-cio stopniowej zadowolenie z działań pracodawcy na rzecz przedłużenia aktywności zawodowej pracowników po przekroczeniu wieku emerytalnego
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>
            1 oznacza minimalne chęci
          </Typography>
          <Typography>
            10 oznacza maksymalne chęci
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <ButtonGroupController
            name='20'
            examId={examId}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </div>
  )
}

export default ExamAdditionalSection