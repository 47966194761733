import {QuestionType} from './exam.types'
import {Occupations} from "../../testedPersons/testedPersonOccupationSelect/TestedPersonOccupationSelect.types";

export const EXAM_QUESTIONS: QuestionType[] = [
    {
      id: 0,
      code: "B130",
      title: "Funkcje energii życiowej i witalności",
      question: `Ogólne funkcje umysłowe, o podłożu fizjologicznym i psychologicznym, które pobudzają do stałego zaspakajania określonych potrzeb i celów ogólnych. Włącza sie: funkcje poziomu energii, motywacje, apetyt, ogromny głód czegoś, w tymi substancji, które powodują uzależnienie Wyłącza się: funkcje świadomości (b110); temperament i funkcje osobowościowe (b126); funkcje snu (b134); funkcje aktywności psychoruchowej (b147); funkcje emocjonalne (b152)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często w ostatnim czasie nie chciało się Panu/Pani nic robić?",
            code: "B130_1",
            answers:[
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często czuje Pan/Pani konieczność spożywania tytoniu i/lub alkoholu?",
            code: "B130_2",
            answers:[
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ],
      }
    },
    {
      id: 1,
      code: "B134",
      title: "Funkcje snu",
      question: `Ogólne funkcje umysłowe okresowego, odwracalnego i wybiórczego, fizycznego i umysłowego wyłączania się ze swojego najbliższego otoczenia, któremu towarzyszą charakterystyczne zmiany fizjologiczne. Obejmuje: funkcje ilości snu, początku snu, utrzymywanie snu, jakość snu, cykl snu, bezsenność, nadmierną potrzebę snu, sen napadowy Nie obejmuje: funkcje świadomości (b110); funkcje energii życiowej i napędu (b130); funkcje uwagi (b140); funkcje aktywności psychoruchowej (b147)`,
      crucialForOccupations: [Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często odczuwa Pan/Pani problemy ze snem (z zasypianiem, wybudzaniem się)?",
            code: "B134_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często jest Pan/Pani nadmiernie senny/a w ciągu dnia?",
            code: "B134_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 2,
      code: "B140",
      title: "Funkcje uwagi",
      question: `Swoiste funkcje psychiczne umożliwiające skupienie się przez niezbędny odstęp czasu na bodźcu zewnętrznym lub przeżyciach wewnętrznych. Obejmuje: funkcje trwałości, przerzutności, zmiennej podzielności i dzielenia uwagi, koncentracja, rozpraszalność Nie obejmuje: funkcje świadomości (b110); funkcje energii i napędu (b130); funkcje snu (b134); funkcje pamięci (b144); funkcje psychomotoryczne (b147); funkcje percepcyjne (b156)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często występują u Pan/Pani problemy z koncentracją i podzielnością uwagi?",
            code: "B140_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 3,
      code: "B144",
      title: "Funkcje pamięci",
      question: `Swoiste funkcje psychiczne umożliwiające rejestrowanie i przechowywanie informacji oraz - w razie potrzeby – odtwarzanie jej. Obejmuje: funkcje związane z pamięcią krótkotrwałą i długotrwałą, natychmiastową, świeżą i odległą; rozpiętość pamięci; odtwarzanie pamięci, przypominanie; funkcje wykorzystywane w trakcie przywoływania z pamięci i uczenia się jak w niepamięci nominalnej, wybiórczej i dysocjacyjnej. Nie obejmuje: funkcje świadomości (b110); funkcje orientacji (b114); funkcje intelektualne (b117); funkcje uwagi (b140); funkcje percepcyjne (b156); funkcje myślenia (b160); wyższe funkcje poznawcze (b164); funkcje językowe (b167); funkcje liczenia (b172)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często ma Pan/Pani problemy z pamięcią (zapominanie dat, imion)?",
            code: "B144_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 5,
      code: "B152",
      title: "Funkcje emocjonalne",
      question: `Swoiste funkcje psychiczne odnoszące się do uczuć i afektywnych składników procesów umysłu. Obejmuje: funkcje związane z dostosowaniem emocji, regulacją i rozpiętością emocji; afekt, smutek, szczęście, miłość, strach, gniew, nienawiść, napięcie, lęk, radość, żal, chwiejność emocjonalną; spłycenie afektu Nie obejmuje: funkcje temperamentu i osobowości (b126); funkcje energii i napędu (b130)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często odczuwa Pan/Pani skrajny smutek lub gniew?",
            code: "B152_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często odczuwa Pan/Pani napięcie emocjonalne lub lęk?",
            code: "B152_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 6,
      code: "B240",
      title: "Wrażenia związane z funkcją słyszenia i funkcją przedsionka",
      question: `Uczucie zaburzonej równowagi, upadania, szumów usznych, zawrotów głowy. Obejmuje: uczucie dzwonienia w uszach, podrażnienia uszu, ucisk uszny, nudności związane z kręceniem się w głowie lub zawrotami głowy Nie obejmuje: funkcje przedsionka (b235); czucie bólu (b280)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często zdarza się Panu/Pani uczucie braku równowagi, zawrotów głowy, chwiania się?",
            code: "B240_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 7,
      code: "B280",
      title: "Czucie bólu",
      question: `Wrażenie nieprzyjemnego doznania sygnalizującego potencjalne lub faktyczne uszkodzenie struktury ciała. Obejmuje: uczucie uogólnionego lub umiejscowionego bólu w jednej lub więcej niż w jednej części ciała, ból w dermatomie, ból kłujący, ból piekący, ból tępy, pobolewania, upośledzenia jak: ból mięśniowy, analgezja, przeczulica bólowa`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Czy odczuwa Pani/Pan ból bardzo przeszkadzający w codziennym funkcjonowaniu?",
            code: "B280_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ],
      }
    },
    {
      id: 8,
      code: "B420",
      title: "Funkcje związane z ciśnieniem tętniczym krwi",
      question: `Funkcje utrzymywania odpowiedniego ciśnienia tętniczego krwi. Obejmuje: funkcje utrzymywania ciśnienia tętniczego krwi; podwyższone i obniżone ciśnienie krwi; upośledzenia związane z niedociśnieniem, nadciśnieniem i hipotonią ortostatyczną Nie obejmuje: funkcje serca (b410); funkcje naczyń krwionośnych (b415); funkcje związane z tolerancją wysiłku (b455)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często występuje u Pana/Pani problem z ciśnieniem tętniczym?",
            code: "B420_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ],
      }
    },
    {
      id: 9,
      code: "B455",
      title: "Funkcje związane z tolerancją wysiłku",
      question: `Funkcje wydolności oddechowej i krążeniowej wymaganej do ciągłego wysiłku fizycznego. Obejmuje: funkcje wydolności fizycznej, pochłaniania tlenu; wytrzymałości i zmęczenia Nie obejmuje: funkcje układu krążenia (b410-b429); funkcje układu krwiotwórczego (b430); funkcje oddychania (b440); funkcje mięśni oddechowych (b445); dodatkowe funkcje związane z oddychaniem (b450)`,
      crucialForOccupations: [Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Czy w ostatnim czasie (pół roku) czuł/a Pan/Pani zmęczenie/przyśpieszony oddech/podwyższone tętno (tzw. zadyszka) podczas wykonywania codziennych czynności np. sprzątania, wchodzenia po schodach, niesienia zakupów?",
            code: "B455_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ],
      }
    },
    {
      id: 10,
      code: "B730",
      title: "Funkcje związane z siłą mięśni",
      question: `Funkcje związane z siłą wytwarzaną przez skurcz pojedynczego mięśnia lub grup mięśni. Obejmuje: funkcje związane z siłą określonych mięśni i grup mięśni, mięśni jednej kończyny, mięśni jednej strony ciała, mięśni dolnej połowy ciała, mięśni wszystkich kończyn, mięśni tułowia i wszystkich mięśni ciała; upośledzenie funkcji jak osłabienie małych mięśni stóp i rąk niedowład mięśnia, porażenie mięśnia, porażenie jednej kończyny, porażenie połowicze, porażenie kończyn dolnych, porażenie czterokończynowe, mutyzm akinetyczny (brak zdolności wykonywania ruchów - bezruch) Nie obejmuje: funkcje narządów dodatkowych oka (b215); funkcje związane z obecnością napięcia mięśni (b735); funkcje związane z wytrzymałością mięśni (b740)`,
      crucialForOccupations: [Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak upośledzenia", label: "0"},
        {value: 1, description: "Niewielkie upośledzenie", label: "1"},
        {value: 2, description: "Umiarkowane upośledzenie", label: "2"},
        {value: 3, description: "Znaczne upośledzenie", label: "3"},
        {value: 4, description: "Skrajne upośledzenie", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        breakpoint: {
          header: "Czy w ostatnim czasie odczuwa Pani/Pan osłabienie siły:",
          questions: [
            {
              question: "kończyn górnych",
              code: "B730_1",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "kończyn dolnych",
              code: "B730_2",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            },
            {
              question: "pleców lub brzucha",
              code: "B730_3",
              answers: [
                {value: 0, description: "Wcale", label: "0"},
                {value: 1, description: "Rzadko", label: "1"},
                {value: 2, description: "Czasami", label: "2"},
                {value: 3, description: "Często", label: "3"},
                {value: 4, description: "Bardzo często", label: "4"},
              ]
            }
          ]
        }
      }
    },
    {
    id: 11,
    code: "D110",
    title: "Patrzenie",
    question: `Zamierzone posługiwanie się zmysłem wzroku w celu odbioru bodźców wzrokowych, jak np. podczas oglądania wydarzenia sportowego lub obserwowania bawiących się dzieci.`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
      {value: 0, description: "Brak problemu", label: "0"},
      {value: 1, description: "Łagodny problem", label: "1"},
      {value: 2, description: "Umiarkowany problem", label: "2"},
      {value: 3, description: "Znaczny problem", label: "3"},
      {value: 4, description: "Skrajny problem", label: "4"},
      {value: 8, description: "Nieokreślone", label: "8"},
      {value: 9, description: "Nie dotyczy", label: "9"}
    ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często występuje u Pana/Pani problem z widzeniem podczas patrzenia w dal i/lub z bliska?",
            code: "D110_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Czy ma Pan/Pani problem z rozróżnianiem barw?",
            code: "D110_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Czy po ciemku zauważa Pan/Pani u siebie gorsze widzenie?",
            code: "D110_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
  },
    {
      id: 12,
      code: "D115",
      title: "Słuchanie",
      question: `Zamierzone posługiwanie się zmysłem słuchu w celu odbioru bodźców słuchowych, jak np. podczas słuchania radia, muzyki lub wykładu.`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często ma Pan/Pani problem ze zrozumieniem mowy w głośnym otoczeniu?",
            code: "D115_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często ogląda Pan/Pani telewizję lub słucha radia zdecydowanie głośniej niż inni?",
            code: "D115_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 13,
      code: "D175",
      title: "Rozwiązywanie problemów",
      question: `Znajdowanie odpowiedzi na pytania lub sytuacje poprzez identyfikowanie i analizowanie problemów, ustalanie możliwych rozwiązań i ocenianie potencjalnych skutków tych rozwiązań a następnie wdrażanie wybranego rozwiązania, jak np. rozstrzyganie sporu pomiędzy dwoma osobami. Obejmuje: rozwiązywanie prostych i złożonych problemów Nie obejmuje: myślenie (d163), podejmowanie decyzji (d177)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często miał/a Pan/Pani trudności z rozwiązywaniem problemów zawodowych?",
            code: "D175_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani trudności z ocenianiem skutków sytuacji problemowych?",
            code: "D175_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 14,
      code: "D177",
      title: "Podejmowanie decyzji",
      question: `Dokonywanie wyboru spośród różnych możliwości, wdrażanie dokonanego wyboru, ocenianie skutków dokonanego wyboru, jak np. wybieranie i kupowanie określonego artykułu lub decydowanie o podjęciu się i podejmowanie jakiegoś zadania spośród wielu zadań, które należy wykonać. Nie obejmuje: myślenie (d163), rozwiązywanie problemów (d175)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często miał/a Pan/Pani problemy z podejmowaniem decyzji w życiu zawodowym?",
            code: "D177_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 15,
      code: "D240",
      title: "Radzenie sobie ze stresem i innymi obciążeniami psychicznymi",
      question: `Wykonywanie prostych lub złożonych i skoordynowanych działań, związanych z kontrolowaniem obciążeń psychicznych występujących podczas wykonywania zadań, które wiążą się z ponoszeniem znacznej odpowiedzialności, narażeniem na stres, czynniki zakłócające lub sytuacje kryzysowe, jak np. w trakcie kierowania pojazdem w czasie wielkiego natężenia ruchu ulicznego lub sprawowania opieki nad liczną grupą dzieci. Obejmuje: ponoszenie odpowiedzialności; radzenie sobie ze stresem i sytuacjami kryzysowymi.`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często miał/a Pan/Pani problemy z radzeniem sobie z napięciem w trudnych sytuacjach w pracy?",
            code: "D240_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często miał/a Pan/Pani problemy z opanowaniem się w sytuacjach kryzysowych?",
            code: "D240_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 16,
      code: "D360",
      title: "Używanie urządzeń i technik służących do porozumiewania się",
      question: `Wykorzystywanie urządzeń, technik i innych środków do porozumiewania się,  np. używanie telefonu aby zadzwonić do przyjaciela. Obejmuje: używanie urządzeń telekomunikacyjnych, używanie maszyn do pisania i technik do porozumiewania się`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często występują u Pana/Pani trudności z wysłaniem sms-a, e-maila?",
            code: "D360_1",
            answers: [
              {value: 0, description: "Wcale/Rzadko/Czasami", label: "0"},
              {value: 1, description: "Często", label: "1"},
              {value: 2, description: "Bardzo często", label: "2"},
            ]
          }
        ]
      }
    },
    {
      id: 17,
      code: "D415",
      title: "Utrzymywanie pozycji ciała",
      question: `Pozostawanie w tej samej wymaganej pozycji ciała, tak jak np. siedzenie lub stanie w pracy lub w szkole. Obejmuje: utrzymywanie pozycji leżącej, kucznej, klęczącej, siedzącej i stojącej`,
      crucialForOccupations: [Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często występuje u Pana/Pani problem z utrzymaniem pozycji kucznej (kucanie) lub klęcznej?",
            code: "D415_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często występuje u Pana/Pani problem z utrzymaniem pozycji siedzącej przez co najmniej godzinę?",
            code: "D415_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często występuje u Pana/Pani problem z utrzymaniem pozycji stojącej, wyprostowanej przez co najmniej pół godziny?",
            code: "D415_3",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 18,
      code: "D430",
      title: "Podnoszenie i przenoszenie przedmiotów",
      question: `Podnoszenie przedmiotu lub zabieranie czegoś z jednego miejsca na drugie, tak jak np. podczas unoszenia filiżanki lub przenoszenia dziecka z jednego pokoju do drugiego. Obejmuje: podnoszenie, przenoszenie za pomocą rąk, na barkach, na biodrach, na plecach lub na głowie, odkładanie`,
      crucialForOccupations: [Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często występuje u Pana/Pani problem z podnoszeniem i przeniesieniem przedmiotów o średniej masie (2 do 9 kg)?",
            code: "D430_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często występuje u Pana/Pani problem podnoszeniem i z przenoszeniem ciężkich przedmiotów, (powyżej 9kg) np. zgrzewka wody?",
            code: "D430_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          }
        ]
      }
    },
    {
      id: 19,
      code: "D440",
      title: "Precyzyjne używanie ręki",
      question: `Wykonywanie skoordynowanych czynności związanych z posługiwaniem się przedmiotami, podnoszeniem, manipulowaniem i wypuszczaniem przedmiotów przy użyciu jednej ręki, palców i kciuka, jak np. gdy zbiera się monety ze stołu lub wykręca numer telefonu. Obejmuje: podnoszenie, chwytanie, manipulowanie i odkładanie Nie obejmuje: podnoszenie i przenoszenie przedmiotów (d430)`,
      crucialForOccupations: [ Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często występuje u Pana/Pani problem z chwytaniem małych przedmiotów, korzystaniem z klawiatury telefonu?",
            code: "D440_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 20,
      code: "D445",
      title: "Używanie rąk i ramion",
      question: `Podejmowanie skoordynowanych działań, których celem jest poruszanie lub manipulowanie przedmiotami za pomocą rąk i ramion, tak jak np. gdy obraca się gałkę u drzwi lub gdy rzuca się lub łapie jakiś przedmiot. Obejmuje: przyciąganie lub popychanie przedmiotów, obracanie lub skręcanie rąk lub ramion; rzucanie, łapanie; Nie obejmuje: precyzyjne używanie ręki (d440)`,
      crucialForOccupations: [Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często występuje u Pana/Pani problem z przyjmowaniem różnych pozycji rąk, sięganiem np. do przedmiotu znajdującego się po drugiej stronie stołu?",
            code: "D445_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często występuje u Pana/Pani problem z sięganiem po przedmioty znajdujące się powyżej głowy?",
            code: "D445_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 21,
      code: "D450",
      title: "Chodzenie",
      question: `Poruszanie się po powierzchni na stopach, krok po kroku, gdy co najmniej jedna stopa zawsze dotyka ziemi, tak jak np. podczas spacerowania, przechadzania się, chodzenia do przodu, do tyłu lub bokiem. Obejmuje: chodzenie na krótkie lub długie dystanse, chodzenie po różnych powierzchniach, omijanie przeszkód Nie obejmuje:  przemieszczanie się (d420); inne sposoby poruszania się (d455)`,
      crucialForOccupations: [Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Czy w ostatnim czasie miał/a Pani/Pan problemy z:chodzeniem po nierównym podłożu i przechodzeniem przez przeszkody np. progi, chodzeniem po schodach?",
            code: "D450_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Czy w ostatnim czasie miał/a Pani/Pan problemy z chodzeniem na dłuższe dystanse np. powyżej 1 km",
            code: "D450_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 22,
      code: "D475",
      title: "Prowadzenie pojazdu",
      question: `Kontrolowanie i kierowanie pojazdem lub zwierzęciem które go porusza, podróżowanie w wybranym przez siebie kierunku lub dysponowanie środkiem transportu, takim jak np. samochód, rower, łódź lub pojazd poruszany przez zwierzęta. Obejmuje: kierowanie pojazdem poruszanym siłą mięśni ludzkich; pojazdem zmotoryzowanym, pojazdem poruszanym przez zwierzęta Nie obejmuje: poruszanie się przy pomocy sprzętu (d465), używanie środków transportu (d470)`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.OFFICE_WORKERS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS, Occupations.FARMERS_GARDENERS_FORESTERS_AND_FISHERMEN, Occupations.INDUSTRIAL_WORKERS_AND_CRAFTSMEN,Occupations.PLANT_AND_MACHINE_OPERATORS_AND_ASSEMBLERS,Occupations.WORKERS_DOING_SIMPLE_JOBS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
    },
    {
      id: 23,
      code: "D720",
      title: "Złożone kontakty międzyludzkie",
      question: `Utrzymywanie i kontrolowanie wzajemnych kontaktów z innymi ludźmi, w sposób odpowiedni do danej sytuacji i akceptowany społecznie tak jak np. kontrolowanie emocji i odruchów, kontrolowanie werbalnej i fizycznej agresji, działanie niezależne od relacji społecznych, działanie zgodnie z zasadami i zwyczajami społecznymi. Obejmuje: tworzenie i kończenie wzajemnych kontaktów; kontrolowanie zachowań w ramach wzajemnych kontaktów, nawiązywanie wzajemnych kontaktów stosownie do pełnionych ról społecznych i zachowywanie dystansu w relacjach społecznych`,
      crucialForOccupations: [Occupations.REPRESENTATIVES_OF_PUBLIC_AUTHORITIES_OFFICIALS_MANAGERS, Occupations.SPECIALISTS,Occupations.TECHNICIANS_AND_OTHER_ASSOCIATE_PROFESSIONALS, Occupations.SERVICE_EMPLOYEES_AND_SELLERS],
      answers: [
        {value: 0, description: "Brak problemu", label: "0"},
        {value: 1, description: "Łagodny problem", label: "1"},
        {value: 2, description: "Umiarkowany problem", label: "2"},
        {value: 3, description: "Znaczny problem", label: "3"},
        {value: 4, description: "Skrajny problem", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak często występują u Pana/Pani problemy ze współpracownikami?",
            code: "D720_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
          {
            question: "Jak często występują u Pana/Pani problemy z innymi osobami (sprzeczka np. w sklepie)?",
            code: "D720_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Rzadko", label: "1"},
              {value: 2, description: "Czasami", label: "2"},
              {value: 3, description: "Często", label: "3"},
              {value: 4, description: "Bardzo często", label: "4"},
            ]
          },
        ]
      }
    },
    {
      id: 24,
      code: "E1101",
      title: "Leki",
      question: `Każdy naturalny lub wytworzony przez człowieka produkt lub substancja, gromadzony, przetwarzany lub produkowany w celach leczniczych taki jak leki alopatyczne i naturopatyczne.`,
      crucialForOccupations:[],
        answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak bardzo przyjmowane leki ułatwiają Ci funkcjonowanie?",
            code: "E1101_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
          {
            question: "Jak bardzo przyjmowane leki utrudniają Ci funkcjonowanie (np. przytępiają uwagę)?",
            code: "E1101_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
        ]
      }
    },
    {
      id: 25,
      code: "E115",
      title: "Produkty i technologie do osobistego użytku w życiu codziennym",
      question: `Sprzęt, wyroby i technologie stosowane przez ludzi w codziennej działalności, w tym przystosowane lub specjalnie zaprojektowane, umiejscowione wewnątrz, na ciele lub w pobliżu używającej je osoby. Obejmuje: ogólne i wspomagające produkty i technologia do użytku osobistego`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak bardzo produkty i technologie osobistego użytku (np. okulary, cewnik) ułatwiają Ci funkcjonowanie?",
            code: "E115_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
          {
            question: "Jak bardzo produkty i technologie osobistego użytku (np. okulary, cewnik) utrudniają Ci funkcjonowanie?",
            code: "E115_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
        ]
      }
    },
    {
      id: 26,
      code: "E125",
      title: "Produkty i technologie służące do porozumiewania się",
      question: `Wyposażenie, produkty i technologie wykorzystywane przez ludzi do wysyłania i odbierania informacji, z włączeniem tych, które zostały specjalnie zaadaptowane lub zaprojektowane, umiejscowione wewnątrz, na ciele lub w pobliżu używającej je osoby Obejmuje: ogólne i wspomagające produkty i technologie używane do porozumiewania się.`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak bardzo produkty i technologie służące do porozumiewania się (np. aparat słuchowy) ułatwiają Ci funkcjonowanie?",
            code: "E125_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
          {
            question: "Jak bardzo produkty i technologie służące do porozumiewania się (np. aparat słuchowy) utrudniają Ci funkcjonowanie?",
            code: "E125_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
        ]
      }
    },
    {
      id: 27,
      code: "E135",
      title: "Produkty i technologie stosowane w miejscu zatrudnienia",
      question: `Wyposażenie, produkty i technologie stosowane w miejscu zatrudnienia dla ułatwienia czynności związanych z pracą. Obejmuje: ogólne i wspomagające produkty i technologie stosowane w pracy`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak bardzo produkty i technologie stosowane w miejscu zatrudnienia (np. regulowane stoły, zdalnie sterowane drzwi, sprzęt komputerowy, oprogramowanie) ułatwiają Ci pracę?",
            code: "E135_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
          {
            question: "Jak bardzo produkty i technologie stosowane w miejscu zatrudnienia (np. zbyt trudne programy komputerowe, brak możliwości dostosowania stanowiska pracy do swoich potrzeb) utrudniają Ci pracę?",
            code: "E135_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
        ]
      }
    },
    {
      id: 28,
      code: "E310",
      title: "Najbliższa rodzina",
      question: `Osoby spokrewnione przez urodzenie, małżeństwo lub inne związki uznawane zgodnie z normami kulturowymi za najbliższą rodzinę jak: małżonkowie, partnerzy, rodzice, rodzeństwo, dzieci, rodzina zastępcza, rodzice adopcyjni i dziadkowie. Nie obejmuje: dalszą rodzinę (e315); opiekunów asystentów osobistych (e340)`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak bardzo rodzina jest wspierająca w Twoim życiu zawodowym?",
            code: "E310_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
          {
            question: "Jak bardzo rodzina jest barierą w Twoim funkcjonowaniu w życiu zawodowym?",
            code: "E310_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
        ]
      }
    },
    {
      id: 29,
      code: "E325",
      title: "Znajomi, rówieśnicy, koledzy, sąsiedzi i członkowie lokalnej społeczności",
      question: `Osoby wzajemnie znające się jako rówieśnicy, koledzy, sąsiedzi i członkowie lokalnej społeczności, w sytuacjach takich jak praca, szkoła, rekreacja lub inne aspekty życia, oraz posiadające wspólne cechy demograficzne takie jak wiek, płeć, wyznanie religijne lub pochodzenie etniczne lub realizujące wspólne zainteresowania. Nie obejmuje: stowarzyszenia i usługi związane ze zrzeszaniem się (e5550)`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak bardzo aktualne relacje ze znajomymi i kolegami ułatwiają Ci funkcjonowanie i pracę?",
            code: "E325_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
          {
            question: "Jak bardzo aktualne relacje ze znajomymi i kolegami utrudniają Ci funkcjonowanie i pracę?",
            code: "E325_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
        ]
      }
    },
    {
      id: 30,
      code: "E330",
      title: "Ludzie posiadający władzę (zwierzchnicy)",
      question: `Osoby, które ponoszą odpowiedzialność za podejmowanie decyzji na rzecz innych i mają społecznie uznany wpływ lub władzę wynikającą z ich społecznej, ekonomicznej, kulturowej lub religijnej roli w społeczeństwie, jak np.: nauczyciele, pracodawcy, osoby sprawujące nadzór, przywódcy religijni, decydenci, kuratorzy, powiernicy.`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak bardzo zwierzchnicy ułatwiają Ci funkcjonowanie i pracę?",
            code: "E330_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
          {
            question: "Jak bardzo zwierzchnicy utrudniają Ci funkcjonowanie i pracę?",
            code: "E330_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
        ]
      }
    },
    {
      id: 31,
      code: "E335",
      title: "Podwładni",
      question: `Osoby, na których codzienne życie wywierają wpływ ludzie posiadający władzę (zwierzchnicy) w pracy, w szkole lub innych okolicznościach, jak: studenci, pracownicy, członkowie grup religijnych. Nie obejmuje: najbliższą rodzinę (e310)`,
      crucialForOccupations:[],
      answers: [
        {value: 14, description: "Całkowite ułatwienie", label: "+4"},
        {value: 13, description: "Znaczne ułatwienie", label: "+3"},
        {value: 12, description: "Umiarkowane ułatwienie", label: "+2"},
        {value: 11, description: "Nieznaczne ułatwienie", label: "+1"},
        {value: 0, description: "Brak bariery/ułatwienia", label: "0"},
        {value: 1, description: "Nieznaczna bariera", label: "1"},
        {value: 2, description: "Umiarkowana bariera", label: "2"},
        {value: 3, description: "Znaczna bariera", label: "3"},
        {value: 4, description: "Całkowita bariera", label: "4"},
        {value: 8, description: "Nieokreślone", label: "8"},
        {value: 9, description: "Nie dotyczy", label: "9"}
      ],
      detailedQuestions: {
        questions: [
          {
            question: "Jak bardzo podwładni ułatwiają Ci funkcjonowanie i pracę?",
            code: "E335_1",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
          {
            question: "Jak bardzo podwładni utrudniają Ci funkcjonowanie i pracę?",
            code: "E335_2",
            answers: [
              {value: 0, description: "Wcale", label: "0"},
              {value: 1, description: "Nieznacznie", label: "1"},
              {value: 2, description: "Umiarkowanie", label: "2"},
              {value: 3, description: "Znacznie", label: "3"},
              {value: 4, description: "Całkowicie", label: "4"},
              {value: 9, description: "Nie dotyczy", label: "9"},
            ]
          },
        ]
      }
    },
  ]