import React, {FC} from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
  createStyles,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {useExamResultsTableStyles} from "./ExamResultsTable.styles";
import {Theme} from "@material-ui/core/styles";
import { EXAM_QUESTIONS } from '../examUtils/examQuestions.utils';
import { ResultData } from '../../../pages/ExamResultPage';
import clsx from 'clsx';

interface ExamResultsTableProps {
  data: Array<{
    code: string,
    title: string,
    color?: string
  }>;
}

const ExamResultsTable: FC<ExamResultsTableProps> = (
  {
    data
  }) => {
  const classes = useExamResultsTableStyles()

  const CellWithLeftBorder = withStyles((theme: Theme) =>
    createStyles({
      root: {
        borderLeftWidth: 1,
        borderLeftColor: theme.palette.grey[300],
        borderLeftStyle: "solid",
      },
    })
  )(TableCell);

  return (
    <Box marginY={5}>
      <Typography
        variant={"h5"}
        component={"span"}
        className={classes.title}
      >
        Zestawienie wyników
      </Typography>

      <div>
        <TableContainer component={Paper} className={"print-tableContainer"}>
          <Table size="small" className={"print-table"}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography className={classes.fieldName}>Kod ICF</Typography>
                </TableCell>

                <TableCell>
                  <Typography className={classes.fieldName}>Nazwa</Typography>
                </TableCell>

                <CellWithLeftBorder align={"center"} width={"130px"} className={"print-cellWithLeftBorder"}>
                  <Typography className={classes.fieldName}>
                    Strefa zielona
                  </Typography>
                </CellWithLeftBorder>

                <CellWithLeftBorder align={"center"} width={"130px"} className={"print-cellWithLeftBorder"}>
                  <Typography className={classes.fieldName}>
                    Strefa pomarańczowa
                  </Typography>
                </CellWithLeftBorder>

                <CellWithLeftBorder align={"center"} width={"130px"} className={"print-cellWithLeftBorder"}>
                  <Typography className={classes.fieldName}>
                    Strefa czerwona
                  </Typography>
                </CellWithLeftBorder>
              </TableRow>
            </TableHead>

            <TableBody>
              {data.map(item => (
                <TableRow key={item.code}>
                  <TableCell>
                    {item.code}
                  </TableCell>

                  <TableCell>
                    {item.title}
                  </TableCell>

                  <TableCell>
                    {item?.color === "green" && (
                      <div className={classes.circleWrapper}>
                        <div className={clsx(classes.circle, classes.green, "print-circle")}/>
                      </div>
                    )}
                  </TableCell>

                  <TableCell>
                    {item?.color === "yellow" && (
                      <div className={classes.circleWrapper}>
                        <div className={clsx(classes.circle, classes.yellow, "print-circle")}/>
                      </div>
                    )}
                  </TableCell>

                  <TableCell>
                    {item?.color === "red" && (
                      <div className={classes.circleWrapper}>
                        <div className={clsx(classes.circle, classes.red, "print-circle")}/>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Box>
  )
}

export default ExamResultsTable