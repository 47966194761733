import React, { FC, useState } from "react"
import AdminPageCard from "../components/adminPageCard/AdminPageCard.component"
import { Box } from "@material-ui/core"
import ExamGroupsList from "../components/examGroup/examGroupsList/ExamGroupsList.component";

const ExamGroupsPage: FC = () => {
  const [isOpenAddExamGroupPopup, setIsOpenAddExamGroupPopup] = useState<boolean>(false)

  return (
    <AdminPageCard
      title="Lista grup do badań"
      actionButtonText={"Dodaj grupę do badań"}
      actionButtonProps={{
        onClick: () => setIsOpenAddExamGroupPopup(true)
      }}
    >
      <Box>
        <ExamGroupsList
          isOpenAddExamGroupPopup={isOpenAddExamGroupPopup}
          onCloseAddExamGroupPopup={() => setIsOpenAddExamGroupPopup(false)}
        />
      </Box>
    </AdminPageCard>
  )
}

export default ExamGroupsPage
