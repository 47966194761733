import { useResultForEmployerFormStyles } from './ResultForEmployerForm.styles';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import TenantsSelect from '../../tenants/tenantsSelect/TenantsSelect.component';
import ExamGroupMultiSelectController from '../../examGroup/examGroupMultiSelect/ExamGroupMultiSelect';
import DateRangeController from '../../formItems/dateRangeController/DateRangeController';
import { format } from 'date-fns'
import { yupResolver } from '@hookform/resolvers/yup';
import { resultForEmployerValidationSchema } from './resultForEmployerForm.validation';
import useFileDownload from '../../../hooks/useFileDownload';

const ResultForEmployerForm = () => {
  const classes = useResultForEmployerFormStyles()
  const form = useForm({
    defaultValues: {
      tenantId: "",
      examGroups: [],
      dateFrom: "",
      dateTo: ''
    },
    resolver: yupResolver(resultForEmployerValidationSchema)
  })

  const [dateForm, dateTo, tenantId, examGroups] = useWatch({
    control: form.control,
    name: ["dateFrom", "dateTo", "tenantId", "examGroups"]
  })

  const {download, downloading, downloadError} = useFileDownload(`/api/batch-result-print/${tenantId}/${dateForm && format(new Date(dateForm), "dd-MM-yyy")}/${dateTo && format(new Date(dateTo), "dd-MM-yyy")}/${examGroups}`)

  const handleSubmit = form.handleSubmit(values => {
    download("Wyniki.pdf")

  })
  
  return (
    <Box className={classes.root}>
      <FormProvider {...form}>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={4} md={4}>
              <TenantsSelect
                name='tenantId'
                label='Podmiot'
                multiple={false}
                disabled={downloading}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <ExamGroupMultiSelectController
                name='examGroups'
                label='Grupa do badań'
                disabled={downloading}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DateRangeController
                nameFrom='dateFrom'
                nameTo='dateTo'
                disabled={downloading}
              />
            </Grid>
          </Grid>
          <Box className={classes.buttonContainer}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => handleSubmit()}
              disabled={downloading}
              >
              Generuj
            </Button>
          </Box>
        </form>
      </FormProvider>

      {downloadError && (
        <Box>
          <Typography>
            Generowanie karty wyników dla pracodawcy nie powiodło się, ilość zakończonych badań musi być nie mniejsza niż 10
          </Typography>
        </Box>
      )}
    </Box>
  )
}

export default ResultForEmployerForm